import React, { useEffect, useState, useRef } from 'react'
import { useParams} from "react-router-dom";
import Moment from 'moment';
import '.././App.css';
import Swal from 'sweetalert2';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function ViewWithGstbill() {
    let { id } = useParams();
    const login_id = user['id'];
    const [SaleData, setSaleData] = useState([]);
    const [searchvalue, setsearchvalue] = useState("");
    useEffect(async () => {
        getSaleData();
        if (searchvalue=='') {
            getSaleData();
        }
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getSaleData(){
        let result = await fetch(""+user.api+"api/get_withgstsale_customer/" + id);
        result = await result.json();
        setSaleData(result);
    }
    // ::::::::::::::::::::::::::::::::::::::::::::::: GET SEARCH DATA :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

        async function getSearchData() {
            const form_data = new FormData();
            form_data.append('searchvalue', searchvalue);
            form_data.append('login_id', login_id);
            
            let result = await fetch(""+user.api+"api/purchase_search", { method: 'POST', body: form_data });
            result = await result.json();
            setSaleData(result);
    }

    async function printbill(id) {
        window.location.href = '/print-withgst-sale-item/'+ id +'';
    }

    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br/>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >||  With GST Bill ||</button><br/><br/><br/>
                        <div className="card card-primary card-outline ex1">
                        {/* <div className="row p-1">
                            &nbsp;&nbsp;<input onKeyUp={getSearchData} type="search"  value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} className='form-control col-sm-4' placeholder='Enter Purchase Details......'/>&nbsp;
                        </div> */}
                        <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Customer Name</th>
                                            <th>Sale Date</th>
                                            <th>Total </th>
                                            <th>Discount</th>
                                            <th>Gst Total</th>
                                            <th>Grand Total</th>
                                            <th>Paid </th>
                                            <th>Remaining</th>
                                            <th width="110">Print</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            SaleData.length===0?
                                            <div style={{padding:"10px"}}>
                                               <h6> No Data Found..........</h6>
                                            </div>
                                            :
                                            SaleData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.cust_name}</td>
                                                    <td>{Moment(item.sale_date).format('DD-MMM-YYYY')}</td>
                                                    <td>{item.total_amount}</td>
                                                    <td>{item.distotal}</td>
                                                    <td>{item.gsttotal}</td>
                                                    <td>{item.grandtotal}</td>
                                                    <td>{item.paid}</td>
                                                    <td>{item.remaining}</td>
                                                    <td width="100">
                                                        <a  onClick={() => printbill(item.id)}  className="btn btn-sm btn-success" > <i className="fa fa-print"></i></a>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}
        </div>
    )
}
