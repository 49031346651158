import React, { useEffect, useState, useRef } from 'react'
import { useParams } from "react-router-dom";
import '.././App.css';
import Moment from 'moment';
import Swal from 'sweetalert2';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function ViewWithoutgstitem() {
    let { id } = useParams();
    const login_id = user['id'];
    const [SaleItemData, setSaleItemData] = useState([]);
    const [SaleData, setSaleData] = useState([]);
    const [remamount, setremamount] = useState(0);
    const [totalamnt, settotalamnt] = useState(0);
    const [ocharge, setocharge] = useState(0);
    
    useEffect(async () => {
        let result = await fetch(""+user.api+"api/get_withgstprintData/" + id);
        result = await result.json();
        setSaleData(result[0]);
        setSaleItemData(result[1]);
        setremamount(result[2]);
        settotalamnt(result[3]);
        setocharge(result[4]);

        const timer = setTimeout(() => {
        window.addEventListener("load", window.print());
          }, 1000);
          return () => clearTimeout(timer);
    }, [])


    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br />
                <section className="content innoiceda">
                    {/* title row */}
                    <div className="row">
                        <div className="col-12">
                            <center>
                                <h1><b>TAX INVOICE</b></h1>
                            </center>
                            <br/>
                            <table className='table'>
                                <tr>
                                    <td style={{textAlign:"center"}}><b>Invoice issued Under Section 31(1) of the CGST Act,2017 the Rule 46 of the CGST Rules 2017</b></td>
                                </tr>
                            </table>
                            <hr/>
                            <br/>
                            <h2 className="page-header">
                                <img src='/logo.png' height={100} width={150}/>
                                <small className="float-right">Date: {Moment(SaleData['sale_date']).format('DD-MMM-YYYY')}</small>
                            </h2>
                        </div>
                        {/* /.col */}
                    </div>
                    <hr/>
                    {/* info row */}
                    <div className="row invoice-info">
                        <br/>
                        <br/>
                        <div className="col-sm-4 invoice-col">
                            From
                            <address>
                                <strong>Shri Photography Designing Group.</strong><br />
                                Bhavani Road,<br />
                                Tuljapur, Tal - Dharashiv.<br />
                                Phone: +91 8483023104, 8983886886 <br />
                                Email: shreedigitaltuljapur@gmail.com
                               
                            </address>
                        </div>
                        {/* /.col */}
                        <div className="col-sm-4 invoice-col">
                            To
                            <address>
                                <strong>{SaleData['cust_name']}</strong><br />
                                Phone: {SaleData['mobile']}<br />
                                {SaleData['address']}<br />
                                {SaleData['state']}<br />
                                Gst No: {SaleData['gst_no']}<br />
                                
                            </address>
                        </div>
                        <div className="col-sm-4 invoice-col">
                            <b>Invoice No : {SaleData['id']}</b><br />
                            <b>Sale Date:</b> {Moment(SaleData['sale_date']).format('DD-MMM-YYYY')}<br />
                            <b>PAN No:</b> CVJPM6030J<br/>
                            <b>GST No:</b> 27CVJPM6030J1ZC
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="row">
                        <div className="col-12 table-responsive">
                            <table className="table table-striped ">
                                <thead>
                                    <tr>
                                    <th><label>Sr No</label></th>
                                    <th><label>Item</label></th>
                                    <th><label>H * W</label></th>
                                    <th><label>Qty</label></th>
                                    <th><label>Sqft</label></th>
                                    <th><label>Rate</label></th>
                                    <th><label> Total </label></th>
                                    <th><label>Dis</label></th>
                                    <th><label>Cgst (%)</label></th>
                                    {
                                      SaleData['state'] == 'Maharashtra'?
                                        <th><label>Sgst (%)</label></th>
                                        :
                                        <th><label>Igst (%)</label></th>
                                    }
                                    <th><label>O. Charges </label></th>
                                    <th><label>All Total</label></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                SaleItemData.map((item, num = 1) =>
                                    <tr key={num+1}>
                                        <td width="80">{num + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.height } * { item.width}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.sqft * item.quantity}</td>
                                        <td>{item.rate}</td>
                                        <td>{item.total_amount } </td>
                                        <td>{item.discount}</td>
                                        <td>{item.gstamount / 2} ({item.gst / 2})</td>
                                        <td>{item.gstamount / 2} ({item.gst / 2})</td>
                                        <td>{parseInt(item.other_charge1) + parseInt(item.other_charge2)}</td>
                                        <td>{item.taxable_amount}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan={12}><p style={{color:"red"}}>Note: Other Charges  =  Fitting Charges + DTP Charges </p></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h5><b>BANK DETAILS</b></h5>
                            <h6><b>Account No -</b>  60323915584 </h6>
                            <h6><b>Account Name -</b>   Shree Photography Designing Group </h6>
                            <h6><b>IFSC Code -</b>  MAHB0000743  </h6>
                            <h6><b>Bank Name -</b>  Bank Of Maharashtra </h6>
                            <h6><b>Branch -</b>  Tuljapur </h6>
                            {/* <p className="lead">Payment Methods:</p>
                            <img src="../../dist/img/credit/visa.png" alt="Visa" />
                            <img src="../../dist/img/credit/mastercard.png" alt="Mastercard" />
                            <img src="../../dist/img/credit/american-express.png" alt="American Express" />
                            <img src="../../dist/img/credit/paypal2.png" alt="Paypal" /> */}
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h3 style={{float:"left"}}>Receiver Signature</h3>
                        </div>
                        {/* /.col */}
                        <div className="col-6">
                            {/* <p className="lead">Amount Due 2/22/2014</p> */}
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                         {/* <tr>
                                            <th>Total Amount</th>
                                            <td>{(Math.round(SaleData['grandtotal'] -  (SaleData['gsttotal'] * 2) ) ).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th>Other Charges</th>
                                            <td>{(Math.round(ocharge * 100) / 100).toFixed(2)}</td>
                                        </tr> */}
                                        <tr>
                                            <th>Total Discount</th>
                                            <td>{(Math.round(SaleData['distotal'] * 100) / 100).toFixed(2)} </td>
                                        </tr>
                                        <tr>
                                            <th>CGST Amount</th>
                                            <td>{(((SaleData['gsttotal']  * 100) / 100) / 2 ).toFixed(2)} </td>
                                        </tr>
                                        <tr>
                                            <th>SGST Amount</th>
                                            <td>{(((SaleData['gsttotal']   * 100) / 100) / 2) .toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: '50%' }}>Grand Total:</th>
                                            <td>{(Math.round(SaleData['grandtotal'] * 100) / 100).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th>Paid Amount</th>
                                            <td>{(Math.round(SaleData['paid'] * 100) / 100).toFixed(2)} </td>
                                        </tr>
                                        <tr>
                                            <th>Remaining Amount</th>
                                            <td>{(Math.round(SaleData['remaining'] * 100) / 100).toFixed(2)} </td>
                                        </tr>
                                    </tbody></table>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h3 style={{float:"right"}}>Shri Photography</h3>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </section>
                {/* /.content */}

                {/* /.content */}

            </div>

            {/* ============================================= modal start ================================================= */}
        </div>
    )
}
