import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import Moment from 'moment';

let user = JSON.parse(localStorage.getItem('user_info'));

export default function Iereport() {
    const login_id = user['id'];
    
    const [saleData, setsaleData] = useState([]);
    const [saleData1, setsaleData1] = useState([]);
    const [expenseData, setexpenseData] = useState([]);
    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");
    const [totalsale, settotalsale] = useState(0);
    const [totalexpense, settotalexpense] = useState(0);
    const [totalrem, settotalrem] = useState(0);
   
   
    async function getReport(){
        if (toDate=='' || fromDate=='') {
            Swal.fire('Sorry!', 'Please Select From Date and To Date', 'error')
        } 
        else {
              const form_data = new FormData();
              form_data.append('fromDate', fromDate);
              form_data.append('toDate', toDate);
              console.log(fromDate);
              let result = await fetch(""+user.api+"api/getStockreport", { method: 'POST', body: form_data });
              result = await result.json();
              console.log(result);
              setsaleData(result[0])
              setsaleData1(result[1])
              setexpenseData(result[2])
              settotalexpense(result[3])
              settotalsale(result[4])
              settotalrem(result[3] - result[4])
        }
    }

    function printReport(){
        var content = document.getElementById("reportprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| Stock Report ||</button><br /><br /><br/>
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <div className='row '>
                                    <div className='col-sm-12'>
                                        <div className='row form-group'>
                                            <div className="col-sm-1"><label>From Date </label> </div>
                                            <div className="col-sm-2">
                                                <input type="date" className='form-control' value={fromDate} onChange={(e) => setfromDate(e.target.value)} />
                                            </div>
                                            <div className="col-sm-1"><label>To Date </label> </div>
                                            <div className="col-sm-2">
                                                <input type="date" className='form-control'value={toDate} onChange={(e) => settoDate(e.target.value)} />
                                            </div>
                                            <div className="col-sm-2">
                                            <center><button type='button' onClick={getReport} className='btn btn-info'>Get Report</button></center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-primary card-outline " id='reportprint'>
                            <br/>
                                <center><h1 style={{color:"red"}}>Stock Report</h1></center>
                            <hr/>
                            <div className="card card-primary card-outline ">
                                <div className="card-body table-responsive p-0" >
                                        <table className="table table-bordered table-hover table-striped table-head-fixed" style={{padding:"10px"},{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <td style={{padding:"10px"}} className='head'><b>REMAINING STOCK</b></td>
                                                    <td style={{padding:"10px"}} className='head'><b>PURCHASE STOCK</b></td>
                                                    <td style={{padding:"10px"}} className='head'><b>SALE STOCK</b></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td  style={{padding:"10px"}}>{totalexpense - totalsale}</td>
                                                <td  style={{padding:"10px"}}>{totalexpense}</td>
                                                <td  style={{padding:"10px"}}>{totalsale}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div className="card-body table-responsive p-0" >
                            <h3>&nbsp;&nbsp;Sale Stock</h3>
                            <table className="table table-bordered table-hover table-striped table-head-fixed" style={{padding:"10px"},{width:"100%"}}>
                                <thead>
                                    <tr >
                                        <td  style={{padding:"10px"}}><b>Date</b></td>
                                        <td  style={{padding:"10px"}}><b>Customer Name</b></td>
                                        <td  style={{padding:"10px"}}><b>Item</b></td>
                                        <td  style={{padding:"10px"}}><b>Quantity</b></td>
                                        <td  style={{padding:"10px"}}><b>Sqft</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                   {
                                       saleData.map((item, num = 1) => 
                                        <tr key={num+1}>
                                           
                                            <td style={{padding:"10px"}}>{Moment(item.sale_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.cust_name}</td>
                                            <td style={{padding:"10px"}}>{item.name}</td>
                                            <td style={{padding:"10px"}}>{item.quantity}</td>
                                            <td style={{padding:"10px"}}>{item.sqft}</td>
                                        </tr>   
                                    )}
                                    {
                                       saleData1.map((item, num = 1) => 
                                        <tr key={num+1}>
                                             <td style={{padding:"10px"}}>{Moment(item.sale_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.cust_name}</td>
                                            <td style={{padding:"10px"}}>{item.name}</td>
                                            <td style={{padding:"10px"}}>{item.quantity}</td>
                                            <td style={{padding:"10px"}}>{item.sqft}</td>
                                        </tr>   
                                    )}
                                </tbody>
                            </table>
                            <hr/>
                            <h3>&nbsp;&nbsp;Purchase Stock</h3>
                            <table className="table table-bordered table-hover table-striped table-head-fixed" style={{padding:"10px"},{width:"100%"}}>
                                <thead>
                                    <tr >
                                        <td  style={{padding:"10px"}}><b>Date </b></td>
                                        <td  style={{padding:"10px"}}><b>Dealer Name </b></td>
                                        <td  style={{padding:"10px"}}><b>Item </b></td>
                                        <td  style={{padding:"10px"}}><b>Quantity </b></td>
                                        <td  style={{padding:"10px"}}><b>Sqft </b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                   {
                                       expenseData.map((item, num = 1) => 
                                        <tr key={num+1}>
                                           
                                            <td style={{padding:"10px"}}>{Moment(item.purchase_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.dealer_name}</td>
                                            <td style={{padding:"10px"}}>{item.name}</td>
                                            <td style={{padding:"10px"}}>{item.quantity}</td>
                                            <td style={{padding:"10px"}}>{item.rate}</td>
                                        </tr>   
                                    )}
                                    
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <center><button className='btn btn-info' onClick={printReport}>Print Report</button></center>
                       <iframe id="ifmcontentstoprint" style={{height: 0, width: 0, position: 'absolute'}} />

                    </div>
                </section>
            </div>

           
        </div>
    )
}
