import React from 'react'
import { useHistory } from 'react-router-dom';

function Menu() {

let user = JSON.parse(localStorage.getItem('user_info'));

 function Logout() {
    // localStorage.clear()
    // window.location.href = "/login";
}
        return (
            <div>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    {/* Brand Logo */}
                    <a href="/home" className="brand-link">
                        <img src="../logo.png" alt="Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">Shri DIGITAL</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img src="../dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                            </div>
                            <div className="info">
                                <a href="#0" className="d-block">{user && user.name}</a>
                            </div>
                        </div>
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item has-treeview ">
                                    <a href="/home" className="nav-link "><i className="nav-icon fa fa-home" />
                                        <p>  Home  </p>
                                    </a>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="/items" className="nav-link "><i className="nav-icon fa fa-product-hunt" />
                                        <p>  Items  </p>
                                    </a>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="#0" className="nav-link "><i className="nav-icon fas fa-users" />
                                        <p> Customers  <i className="right fas fa-angle-left" /></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"><a className="nav-link" href="/customer-mgmt">Customer Mgmt</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/customer-account">Customer Account</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="#0" className="nav-link "><i className="nav-icon fas fa-bars" />
                                        <p> Sales  <i className="right fas fa-angle-left" /></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"><a className="nav-link" href="/without-gst-sale">Without GST Sale</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/with-gst-sale">With GST Sale</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="#0" className="nav-link "><i className="nav-icon fas fa-eye" />
                                        <p> Show Sale  <i className="right fas fa-angle-left" /></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"><a className="nav-link" href="/show-without-gst-sale">Show Without GST Sale</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/show-with-gst-sale">Show With GST Sale</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="#0" className="nav-link "><i className="nav-icon fas fa-user" />
                                        <p> Dealers  <i className="right fas fa-angle-left" /></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"><a className="nav-link" href="/dealer-mgmt">Dealer Mgmt</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/dealer-account">Dealer Account</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="#0" className="nav-link "><i className="nav-icon fas fa-address-card" />
                                        <p> Purchases  <i className="right fas fa-angle-left" /></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"><a className="nav-link" href="/purchase-mgmt">Purchase Mgmt</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/view-purchase">View Purchase</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="/invoice" className="nav-link "><i className="nav-icon fas fa-print" />
                                        <p> Invoices  </p>
                                    </a>
                                </li>
                               
                                {/* <li className="nav-item has-treeview ">
                                    <a href="#0" className="nav-link "><i className="nav-icon fas fa-file" />
                                        <p> Quotations  <i className="right fas fa-angle-left" /></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"><a className="nav-link" href="/qotation-mgmt">Quotation Mgmt</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/view-quotation">View Quotation</a></li>
                                    </ul>
                                </li> */}
                                <li className="nav-item has-treeview ">
                                    <a href="#0" className="nav-link "><i className="nav-icon fas fa-calendar" />
                                        <p> Daily IE  <i className="right fas fa-angle-left" /></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"><a className="nav-link" href="/daily-ie">Daily IE</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="#0" className="nav-link "><i className="nav-icon fas fa-list" />
                                        <p> Report <i className="right fas fa-angle-left" /></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"><a className="nav-link" href="/customer-outstanding-report">Customer Outstanding</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/sale-purchase-report">Sale / Purchase</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/income-expense-report">Income / Expense Report</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/stock-report">Stock Report</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a href="#0" onclick={Logout()} className="nav-link "><i className="nav-icon fa fa-sign-out" />
                                        <p>  Sign Out  </p>
                                    </a>
                                </li>
                               
                            </ul>
                        </nav>

                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>

            </div>
        )
}
export default Menu