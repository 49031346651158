import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Custaccount() {
    const login_id = user['id'];
    const [Cust, setCust] = useState([]);
    let [alltot, setalltot] =  useState(0);
    let [allpaid, setallpaid] =  useState(0);
    let [alldis, setalldis] =  useState(0);
    let [allrem, setallrem] =  useState(0);
    useEffect(async () => {
        getCustaccountData();
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getCustaccountData(){
        let result = await fetch(""+user.api+"api/get_customeraccount/" + login_id);
        result = await result.json();
        setCust(result[0])
        result[0] = Object.values(result[0]);
        setalltot((Math.round(result[1] * 100) / 100).toFixed(2));
        setallpaid((Math.round(result[2] * 100) / 100).toFixed(2));
        setalldis((Math.round(result[3] * 100) / 100).toFixed(2));
        let rem = result[1] - result[2] - result[3];
        setallrem((Math.round(rem * 100) / 100).toFixed(2));
    }
  

    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br/>
                <section className="content">
                    <div className="container-fluid">
                        <a href='/print-customer-outstanding' className='btn btn-success'>Print Report</a>
                        <button className="btn btn-secondary float-6" >|| Customer Outstanding Report ||</button><br/><br/>
                        <div className="card card-primary card-outline ex1">
                        {/* <div className="row p-1">
                            &nbsp;&nbsp;<input onKeyUp={getSearchData} type="search"  value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} className='form-control col-sm-4' placeholder='Enter Dealer Name to Search ......'/>&nbsp;
                        </div> */}
                        <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Customer Name</th>
                                            <th>Customer Mobile</th>
                                            <th>Total Amount</th>
                                            <th>Paid </th>
                                            <th>Discount </th>
                                            <th>Remaining</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Cust.map((item, num = 1, alltot = 0) =>
                                                item[1] - item[2]- item[4]===0?
                                                <></>
                                                :
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item[3][0]['cust_name']}</td>
                                                    <td>{item[3][0]['mobile']}</td>
                                                    <td>{item[1]}</td>
                                                    <td>{item[2]}</td>
                                                    <td>{item[4]}</td>
                                                    <td>{item[1] - item[2]- item[4]}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td colSpan={3}>Total</td>
                                                <td>{alltot}</td>
                                                <td>{allpaid}</td>
                                                <td>{alldis}</td>
                                                <td>{allrem}</td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

           
        </div>
    )
}
