import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import Moment from 'moment';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Item() {
    const login_id = user['id'];
    const [ItemData, setItemData] = useState([]);

    const [searchvalue, setsearchvalue] = useState("");
    const [itemname, setitemname] = useState("");
    const [compname, setcompname] = useState("");
    const [saleprice, setsaleprice] = useState(0);
    const [purchaseprice, setpurchaseprice] = useState(0);
    const [unit, setunit] = useState("NOS");
    const [stock, setstock] = useState(0);
    const [itemdate, setitemdate] = useState(Moment().format('DD-MMM-YYYY'));
    const [hsncode, sethsncode] = useState("");
    const [gstamount, setgstamount] = useState(0);

    const [upitemid, setupitemid] = useState("");
    const [upitemname, setupitemname] = useState("");
    const [upcompname, setupcompname] = useState("");
    const [upsaleprice, setupsaleprice] = useState("");
    const [uppurchaseprice, setuppurchaseprice] = useState("");
    const [upunit, setupunit] = useState("");
    const [upstock, setupstock] = useState("");
    const [upitemdate, setupitemdate] = useState("");
    const [uphsncode, setuphsncode] = useState("");
    const [upgstamount, setupgstamount] = useState("");


    useEffect(async () => {
        getItems();
        if (searchvalue=='') {
            getItems();
        }
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::: INSERT ITEMS CODE START::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function insProduct() {
    const form_data = new FormData();
    form_data.append('login_id', login_id);
    form_data.append('itemname', itemname);
    form_data.append('compname', compname);
    form_data.append('saleprice', saleprice);
    form_data.append('purchaseprice', purchaseprice);
    form_data.append('unit', unit);
    form_data.append('stock', stock);
    form_data.append('itemdate', itemdate);
    form_data.append('hsncode', hsncode);
    form_data.append('gstamount', gstamount);
        if (itemname == '') {
                Swal.fire('Sorry!', 'Please Fill Some Details', 'error')
        } 
        else {
            let result = await fetch(""+user.api+"api/insert_product", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Item Successfully Stored ', 'success');
                getItems();setitemname("");setcompname("");setsaleprice("");setpurchaseprice("");setunit("NOS");setstock(0);setitemdate("");sethsncode("");setgstamount(0);
            } else {
                Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
            }
        }
    }
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getItems(){
        let result = await fetch(""+user.api+"api/get_items/" + login_id);
        result = await result.json();
        setItemData(result);
    }
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::: EDIT ITEM DETAILS START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function editItems(id, name, comp_name, mrp_rate, purchase_rate, size, stock, add_date, hsn_code, gst_no) {
        setupitemid(id);
        setupitemname(name);
        setupcompname(comp_name);
        setupsaleprice(mrp_rate);
        setuppurchaseprice(purchase_rate);
        setupunit(size);
        setupstock(stock);
        setupitemdate(add_date);
        setuphsncode(hsn_code);
        setupgstamount(gst_no);
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::::::: INSERT ITEMS CODE START::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function updateProduct() {
        const form_data = new FormData();
        form_data.append('upitemid', upitemid);
        form_data.append('upitemname', upitemname);
        form_data.append('upcompname', upcompname);
        form_data.append('upsaleprice', upsaleprice);
        form_data.append('uppurchaseprice', uppurchaseprice);
        form_data.append('upunit', upunit);
        form_data.append('upstock', upstock);
        form_data.append('upitemdate', upitemdate);
        form_data.append('uphsncode', uphsncode);
        form_data.append('upgstamount', upgstamount);
            if (upitemname == ''  || upunit=='') {
                    Swal.fire('Sorry!', 'Please Fill Some Details', 'error')
            } 
            else {
                let result = await fetch(""+user.api+"api/update_product", { method: 'POST', body: form_data });
                result = await result.json();
                if (result === 1) {
                    Swal.fire('Success!', 'Item Successfully Updated ', 'success');
                    getItems();
                } else {
                    Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
                }
            }
        }
        
        // :::::::::::::::::::::::::::::::::::::::::::::::::::: DELETE ITEMS CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::

        async function delItems(id) {
            let result = await fetch(""+user.api+"api/delete_items/" + id, {
                method: 'DELETE'
            }, []);
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Item Successfully Deleted!', 'success')
                getItems();
            } else {
                Swal.fire('Sorry!', 'Something was Wrong!', 'error')
            }
        }

        // ::::::::::::::::::::::::::::::::::::::::::::::: GET SEARCH DATA :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

        async function getSearchData() {
            const form_data = new FormData();
            form_data.append('searchvalue', searchvalue);
            form_data.append('login_id', login_id);
            
            let result = await fetch(""+user.api+"api/item_search", { method: 'POST', body: form_data });
            result = await result.json();
            setItemData(result);
    }

    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br/>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| Item Management ||</button>
                        <button className="btn btn-info btn-sm"  data-toggle="modal" data-target="#insert-modal">Add Items</button><br/><br/>
                        <div className="card card-primary card-outline ex1">
                        <div className="row p-1">
                            &nbsp;&nbsp;<input onKeyUp={getSearchData} type="search"  value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} className='form-control col-sm-4' placeholder='Enter Product Details......'/>&nbsp;
                        </div>
                        <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Item Name</th>
                                            <th>Sale Price</th>
                                            <th>Purchase Price</th>
                                            <th>Stock</th>
                                            <th>Unit</th>
                                            <th>GST </th>
                                            <th>Item Date </th>
                                            <th>HSN Code </th>
                                            <th>Company</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ItemData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.mrp_rate}</td>
                                                    <td>{item.purchase_rate}</td>
                                                    <td>{item.stock}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.gst_no}</td>
                                                    <td>{Moment(item.add_date).format('DD-MMM-YYYY')}</td>
                                                    <td>{item.hsn_code}</td>
                                                    <td>{item.comp_name}</td>
                                                    <td width="100">
                                                        <a onClick={() => editItems(item.id, item.name, item.comp_name, item.mrp_rate, item.purchase_rate, item.size, item.stock, item.add_date, item.hsn_code, item.gst_no)} data-toggle="modal" data-target="#text-modal1"  className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>
                                                       <a onClick={() => delItems(item.id)} className="btn btn-sm btn-danger" > <i className="fa fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Items</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>  Item Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={itemname} onChange={(e) => setitemname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                                </div>
                                <div className="col-sm-2"><label>Company Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={compname} onChange={(e) => setcompname(e.target.value)} className="form-control" placeholder="Enter  Company Name ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Sale Price</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={saleprice} onChange={(e) => setsaleprice(e.target.value)} className="form-control" placeholder="Enter Sale Price ..." />
                                </div>
                                <div className="col-sm-2"><label>Purchase Price</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={purchaseprice} onChange={(e) => setpurchaseprice(e.target.value)} className="form-control" placeholder="Enter  Purchase Price ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Select Unit</label> </div>
                                <div className="col-sm-4">
                                    <select className="form-control" value={unit} onChange={(e) => setunit(e.target.value)}>
                                        <option>--Select Unit--</option>
                                        <option value="NOS">NOS</option>
                                        <option value="SQFT">SQFT</option>
                                        <option value="BRASS">BRASS</option>
                                        <option value="HBRASS">HALF BRASS</option>
                                        <option value="BAG">BAG</option>
                                        <option value="MM">MM</option>
                                        <option value="PCS">PCS</option>
                                        <option value="TON">TON</option>
                                        <option value="KG">KG</option>
                                        <option value="MTR">MTR</option>
                                        <option value="INCH">INCH</option>
                                        <option value="FEET">FEET</option>
                                        <option value="NONE">NONE</option>
                                        <option value="FREE SIZE">FREE SIZE</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>Stock</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={stock} onChange={(e) => setstock(e.target.value)} className="form-control" placeholder="Enter  Company Name ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Item Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date" value={itemdate} onChange={(e) => setitemdate(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-2"><label>HSN Code</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={hsncode} onChange={(e) => sethsncode(e.target.value)} className="form-control" placeholder="Enter  HSN Code ..." />
                                </div>
                            </div><br />
                            
                            <div className="row">
                                <div className="col-sm-2"><label> GST</label> </div>
                                <div className="col-sm-4">
                                <select className="form-control" value={gstamount} onChange={(e) => setgstamount(e.target.value)}>
                                        <option>--Select GST Percentage--</option>
                                        <option value="0">0</option>
                                        <option value="5">5</option>
                                        <option value="12">12</option>
                                        <option value="18">18</option>
                                        <option value="28">28</option>
                                    </select>
                                </div>
                                
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={insProduct} className="btn btn-primary" data-dismiss="modal">Save Product</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Items</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>  Item Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={upitemname} onChange={(e) => setupitemname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                                </div>
                                <div className="col-sm-2"><label>Company Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={upcompname} onChange={(e) => setupcompname(e.target.value)} className="form-control" placeholder="Enter  Company Name ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Sale Price</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={upsaleprice} onChange={(e) => setupsaleprice(e.target.value)} className="form-control" placeholder="Enter Sale Price ..." />
                                </div>
                                <div className="col-sm-2"><label>Purchase Price</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={uppurchaseprice} onChange={(e) => setuppurchaseprice(e.target.value)} className="form-control" placeholder="Enter  Purchase Price ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Select Unit</label> </div>
                                <div className="col-sm-4">
                                    <select className="form-control" value={upunit} onChange={(e) => setupunit(e.target.value)}>
                                        <option>--Select Unit--</option>
                                        <option value="NOS">NOS</option>
                                        <option value="SQFT">SQFT</option>
                                        <option value="BRASS">BRASS</option>
                                        <option value="HBRASS">HALF BRASS</option>
                                        <option value="BAG">BAG</option>
                                        <option value="MM">MM</option>
                                        <option value="PCS">PCS</option>
                                        <option value="TON">TON</option>
                                        <option value="KG">KG</option>
                                        <option value="MTR">MTR</option>
                                        <option value="INCH">INCH</option>
                                        <option value="FEET">FEET</option>
                                        <option value="NONE">NONE</option>
                                        <option value="FREE SIZE">FREE SIZE</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>Stock</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={upstock} onChange={(e) => setupstock(e.target.value)} className="form-control" placeholder="Enter  Company Name ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Item Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date" value={upitemdate} onChange={(e) => setupitemdate(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-2"><label>HSN Code</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={uphsncode} onChange={(e) => setuphsncode(e.target.value)} className="form-control" placeholder="Enter  HSN Code ..." />
                                </div>
                            </div><br />
                            
                            <div className="row">
                                <div className="col-sm-2"><label> GST</label> </div>
                                <div className="col-sm-4">
                                <select className="form-control" value={upgstamount} onChange={(e) => setupgstamount(e.target.value)}>
                                        <option>--Select GST Percentage--</option>
                                        <option value="0">0</option>
                                        <option value="5">5</option>
                                        <option value="12">12</option>
                                        <option value="18">18</option>
                                        <option value="28">28</option>
                                    </select>
                                </div>
                                
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={updateProduct} className="btn btn-primary" data-dismiss="modal">Update Product</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


           


        </div>
    )
}
