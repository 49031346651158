import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PageNotFound from './PageNotFound'
import Login from './Logincomp/Login';
import Register from './Logincomp/Register';
import Protected from './Panel/Protected';
import Header from './Panel/Header';
import Menu from './Panel/Menu';
import Footer from './Panel/Footer';
import Tdashboard from './Panel/Dashboard';
import Adashboard from './Panel/Dashboard1';
import Item from './View/Item';
import Customer from './View/Customer';
import Dealer from './View/Dealer';
import Purchase from './View/Purchase';
import Viewpurchase from './View/Viewpurchase';
import Viewpurchaseitem from './View/Viewpurchaseitem';
import Dealeraccount from './View/Dealeraccount';
import Viewledger from './View/Viewledger';
import Withoutgst from './View/Withoutgst';
import ViewWithoutgst from './View/ViewWithoutgst';
import ViewWithoutgstitem from './View/ViewWithoutgstitem';
import Custaccount from './View/Custaccount';
import Viewcustledger from './View/Viewcustledger';
import Withgstsale from './View/Withgstsale';
import ViewwithgstSale from './View/ViewwithgstSale';
import ViewWithgstitem from './View/ViewWithgstitem';
import Invoice from './View/Invoice';
import ViewWithoutGstbill from './View/ViewWithoutGstbill';
import ViewWithGstbill from './View/ViewWithGstbill';
import Printwithoutgst from './View/Printwithoutgst';
import Printwithgst from './View/Printwithgst';
import Customeroutstanding from './View/Customeroutstanding';
import Customeroutstandingprint from './View/Customeroutstandingprint';
import Spreport from './View/Spreport';
import Customerreport from './View/Customerreport';
import Dailyie from './View/Dailyie';
import Iereport from './View/Iereport';
import Stockreport from './View/Stockreport';

let user = JSON.parse(localStorage.getItem('user_info'));
function App() {
  return (
    <div className="wrapper">
      {
        localStorage.getItem('user_info') ?
          <>
            <Menu />
            <Header />
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<Login />} />
                <Route path="/stock-report" element={<Stockreport />} />
                <Route path="/income-expense-report" element={<Iereport />} />
                <Route path="/daily-ie" element={<Dailyie />} />
                <Route path="/print-report-all/:id" element={<Customerreport />} />
                <Route path="/sale-purchase-report" element={<Spreport />} />
                <Route path="/print-customer-outstanding" element={<Customeroutstandingprint />} />
                <Route path="/customer-outstanding-report" element={<Customeroutstanding />} />
                <Route path="/print-withgst-sale-item/:id" element={<Printwithgst />} />
                <Route path="/print-withoutgst-sale-item/:id" element={<Printwithoutgst />} />
                <Route path="/view-with-gst-bill/:id" element={<ViewWithGstbill />} />
                <Route path="/view-without-gst-bill/:id" element={<ViewWithoutGstbill />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/view-with-sale-item/:id" element={<ViewWithgstitem />} />
                <Route path="/show-with-gst-sale" element={<ViewwithgstSale />} />
                <Route path="/with-gst-sale" element={<Withgstsale />} />
                <Route path="/view-customer-ledger/:custid" element={<Viewcustledger />} />
                <Route path="/customer-account" element={<Custaccount />} />
                <Route path="/view-withoutgst-sale-item/:id" element={<ViewWithoutgstitem />} />
                <Route path="/show-without-gst-sale" element={<ViewWithoutgst />} />
                <Route path="/without-gst-sale" element={<Withoutgst />} />
                <Route path="/view-ledger/:dealerid" element={<Viewledger />} />
                <Route path="/dealer-account" element={<Dealeraccount />} />
                <Route path="/view-purchase-item/:id" element={<Viewpurchaseitem />} />
                <Route path="/view-purchase" element={<Viewpurchase />} />
                <Route path="/purchase-mgmt" element={<Purchase />} />
                <Route path="/dealer-mgmt" element={<Dealer />} />
                <Route path="/customer-mgmt" element={<Customer />} />
                <Route path="/home" element={<Tdashboard />} />
                <Route path="/home#" element={<Tdashboard />} />
                <Route path="/#" element={<Tdashboard />} />
                <Route path="/items" element={<Item />} />
              </Routes>
            </BrowserRouter>
          </>
          :
          <>
            <BrowserRouter>
              <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Login />} />
              </Routes>
            </BrowserRouter>
          </>
      }

    </div>
  );
}

export default App;
