import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import Moment from 'moment';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Dealer() {
    const login_id = user['id'];
    const [DealerData, setDealerData] = useState([]);

    const [searchvalue, setsearchvalue] = useState("");
    const [dealername, setdealername] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [address, setaddress] = useState("");
    const [state, setstate] = useState("Maharashtra");
    const [gstno, setgstno] = useState("");
    const [dealerdate, setdealerdate] = useState(Moment().format('YYYYY-MMM-DD'));

    const [upcustid, setupcustid] = useState("");
    const [updealername, setupdealername] = useState("");
    const [upmobile, setupmobile] = useState("");
    const [upemail, setupemail] = useState("");
    const [upaddress, setupaddress] = useState("");
    const [upstate, setupstate] = useState("");
    const [upgstno, setupgstno] = useState("");
    const [updealerdate, setupdealerdate] = useState("");


    useEffect(async () => {
        getDealers();
        if (searchvalue=='') {
            getDealers();
        }
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::: INSERT ITEMS CODE START::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function insCustomer() {
    const form_data = new FormData();
    form_data.append('login_id', login_id);
    form_data.append('dealername', dealername);
    form_data.append('mobile', mobile);
    form_data.append('email', email);
    form_data.append('address', address);
    form_data.append('state', state);
    form_data.append('gstno', gstno);
    form_data.append('dealerdate', dealerdate);
        if (dealername == '') {
                Swal.fire('Sorry!', 'Please Fill Dealer Name', 'error')
        } 
        else {
            let result = await fetch(""+user.api+"api/insert_dealers", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Dealer Information  Successfully Stored ', 'success');
                getDealers();setdealername("");setmobile("");setemail("");setaddress("");setstate("NOS");setgstno(0);setdealerdate("");
            } else {
                Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
            }
        }
    }
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET DealerS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getDealers(){
        let result = await fetch(""+user.api+"api/get_dealers/" + login_id);
        result = await result.json();
        setDealerData(result);
    }
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::: EDIT ITEM DETAILS START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function editDealers(id, dealername, mobile, email, address, state, gstno, cust_date) {
        setupcustid(id);
        setupdealername(dealername);
        setupmobile(mobile);
        setupemail(email);
        setupaddress(address);
        setupstate(state);
        setupgstno(gstno);
        setupdealerdate(cust_date);
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::::::: INSERT DealerS CODE START::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function updateDealer() {
        const form_data = new FormData();
        form_data.append('upcustid', upcustid);
        form_data.append('updealername', updealername);
        form_data.append('upmobile', upmobile);
        form_data.append('upemail', upemail);
        form_data.append('upaddress', upaddress);
        form_data.append('upstate', upstate);
        form_data.append('upgstno', upgstno);
        form_data.append('updealerdate', updealerdate);
            if (updealername == '' ) {
                    Swal.fire('Sorry!', 'Please Fill Dealer Name Details', 'error')
            } 
            else {
                let result = await fetch(""+user.api+"api/update_dealers", { method: 'POST', body: form_data });
                result = await result.json();
                if (result === 1) {
                    Swal.fire('Success!', 'Dealer Information Successfully Updated ', 'success');
                    getDealers();
                } else {
                    Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
                }
            }
        }
        
        // :::::::::::::::::::::::::::::::::::::::::::::::::::: DELETE DealerS CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::

        async function delDealers(id) {
            let result = await fetch(""+user.api+"api/delete_dealers/" + id, {
                method: 'DELETE'
            }, []);
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Dealer Information Successfully Deleted!', 'success')
                getDealers();
            } else {
                Swal.fire('Sorry!', 'Something was Wrong!', 'error')
            }
        }

        // ::::::::::::::::::::::::::::::::::::::::::::::: GET SEARCH DATA :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

        async function getSearchData() {
            const form_data = new FormData();
            form_data.append('searchvalue', searchvalue);
            form_data.append('login_id', login_id);
            
            let result = await fetch(""+user.api+"api/dealer_search", { method: 'POST', body: form_data });
            result = await result.json();
            setDealerData(result);
    }

    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
               <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| Dealer Management ||</button>
                        <button className="btn btn-info btn-sm"  data-toggle="modal" data-target="#insert-modal">Add Dealer</button><br/><br/>
                        <div className="card card-primary card-outline">
                        <div className="row p-1">
                            &nbsp;&nbsp;<input onKeyUp={getSearchData} type="search"  value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} className='form-control col-sm-4' placeholder='Enter Dealer Details......'/>&nbsp;
                        </div>
                        <div className="card-body table-responsive p-0" style={{height: 550}}>
                                <table className="table table-bordered table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Dealer Name</th>
                                            <th>Mobile </th>
                                            <th>Email </th>
                                            <th>Address </th>
                                            <th>State </th>
                                            <th>GST NO </th>
                                            <th>Dealer Date </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            DealerData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.dealer_name}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.state}</td>
                                                    <td>{item.gst_no}</td>
                                                    <td>{Moment(item.dealer_date).format('DD-MMM-YYYY')}</td>
                                                    <td width="100">
                                                        <a onClick={() => editDealers(item.id, item.dealer_name, item.mobile, item.email, item.address, item.state, item.gst_no, item.dealer_date)} data-toggle="modal" data-target="#text-modal1"  className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>
                                                           <a onClick={() => delDealers(item.id)} className="btn btn-sm btn-danger" > <i className="fa fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add New Dealer</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>  Dealer Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={dealername} onChange={(e) => setdealername(e.target.value)} className="form-control" placeholder="Enter  Dealer Name ..." />
                                </div>
                                <div className="col-sm-2"><label>Mobile</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" placeholder="Enter  Dealer Mobile ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>Email</label> </div>
                                <div className="col-sm-4">
                                    <input type="email" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" placeholder="Enter Dealer Email Id ..." />
                                </div>
                                <div className="col-sm-2"><label>Address</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" placeholder="Enter Dealer Address ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  State</label> </div>
                                <div className="col-sm-4">
                                    <select class="form-control" value={state} onChange={(e) => setstate(e.target.value)}>
                                        <option>--Select State--</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                        <option value="Daman and Diu">Daman and Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Lakshadweep">Lakshadweep</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>GST No</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={gstno} onChange={(e) => setgstno(e.target.value)} className="form-control" placeholder="Enter Dealer GST No ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Dealer Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date" value={dealerdate} onChange={(e) => setdealerdate(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={insCustomer} className="btn btn-primary" data-dismiss="modal">Save Dealer</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Dealers</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>  Dealer Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={updealername} onChange={(e) => setupdealername(e.target.value)} className="form-control" placeholder="Enter  Dealer Name ..." />
                                </div>
                                <div className="col-sm-2"><label>Mobile</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={upmobile} onChange={(e) => setupmobile(e.target.value)} className="form-control" placeholder="Enter  Dealer Mobile ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>Email</label> </div>
                                <div className="col-sm-4">
                                    <input type="email" value={upemail} onChange={(e) => setupemail(e.target.value)} className="form-control" placeholder="Enter Dealer Email Id ..." />
                                </div>
                                <div className="col-sm-2"><label>Address</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={upaddress} onChange={(e) => setupaddress(e.target.value)} className="form-control" placeholder="Enter Dealer Address ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  State</label> </div>
                                <div className="col-sm-4">
                                    <select class="form-control" value={upstate} onChange={(e) => setupstate(e.target.value)}>
                                        <option>--Select State--</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                        <option value="Daman and Diu">Daman and Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Lakshadweep">Lakshadweep</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>GST No</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={upgstno} onChange={(e) => setupgstno(e.target.value)} className="form-control" placeholder="Enter Dealer GST No ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Dealer Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date" value={updealerdate} onChange={(e) => setupdealerdate(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={updateDealer} className="btn btn-primary" data-dismiss="modal">Update Dealer</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
