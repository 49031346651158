import React, {  useEffect, useState } from 'react'
let user = JSON.parse(localStorage.getItem('user_info'));
export default function Dashboard() {
let login_id = user['id'];
const [allcust, setallcust] = useState(0)
const [allitems, setallitems] = useState(0)
const [allsale, setallsale] = useState(0)
const [allpurchase, setallpurchase] = useState(0)
useEffect(async() => {
        let result = await fetch(""+user.api+"api/getHomDetails/" + login_id);
        result = await result.json();
        setallcust(result[0]);
        setallitems(result[1]);
        setallsale(result[2]);
        setallpurchase(result[3]);
    }, [])

   
    return (
            <div>
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0 text-dark">DASHBOARD</h1>
                                </div>{/* /.col */}
                            </div>{/* /.row */}
                        </div>{/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            {/* Small boxes (Stat box) */}
                            <div className="row">
                                <div className="col-lg-3 col-6">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>{allitems}</h3>
                                            <p> Items</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fa fa-product-hunt" />
                                        </div>
                                        <a href="/items" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-lg-3 col-6">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>{allcust}</h3>
                                            <p>Consumers</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                        <a href="/customer-mgmt" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-lg-3 col-6">
                                    {/* small box */}
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{allsale}</h3>
                                            <p>Sales</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-bars" />
                                        </div>
                                        <a href="/without-gst-sale" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-lg-3 col-6">
                                    {/* small box */}
                                    <div className="small-box bg-danger">
                                        <div className="inner">
                                            <h3>{allpurchase}</h3>
                                            <p>Purchases</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-address-card" />
                                        </div>
                                        <a href="/purchase-mgmt" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                            </div>
                          
                        </div>{/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>

            </div>
        )
}
