import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import Moment from 'moment';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Invoice() {
    const login_id = user['id'];
    const [ItemData, setCustomerData] = useState([]);
    const [searchvalue, setsearchvalue] = useState("");

    useEffect(async () => {
        getCustomers();
        if (searchvalue=='') {
            getCustomers();
        }
    }, [])
   

    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET CUSTOMERS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getCustomers(){
        let result = await fetch(""+user.api+"api/get_customers/" + login_id);
        result = await result.json();
        setCustomerData(result);
    }
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::: EDIT ITEM DETAILS START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


        async function getSearchData() {
            const form_data = new FormData();
            form_data.append('searchvalue', searchvalue);
            form_data.append('login_id', login_id);
            
            let result = await fetch(""+user.api+"api/customer_search", { method: 'POST', body: form_data });
            result = await result.json();
            setCustomerData(result);
    }

    async function viewWithoutgstbill(id) {
        window.location.href = '/view-without-gst-bill/'+ id +'';
    }

    async function viewWithtgstbill(id) {
        window.location.href = '/view-with-gst-bill/'+ id +'';
    }
    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
               <br/>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| Invoice Management ||</button><br/><br/><br/><br/>
                        <div className="card card-primary card-outline ex1">
                        <div className="row p-1">
                            &nbsp;&nbsp;<input onKeyUp={getSearchData} type="search"  value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} className='form-control col-sm-4' placeholder='Enter Customer Details......'/>&nbsp;
                        </div>
                        <div className="card-body table-responsive p-0" style={{height: 550}}>
                                <table className="table table-bordered table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Customer Name</th>
                                            <th>Mobile </th>
                                            <th>Email </th>
                                            <th>Address </th>
                                            <th width="120">Without Gst </th>
                                            <th  width="110">With Gst </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ItemData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.cust_name}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.address}</td>
                                                    <td>
                                                        <a onClick={() => viewWithoutgstbill(item.id)} className="btn btn-sm btn-info" title='Without Gst Bill View' > <i className="fa fa-eye"></i> View </a>
                                                    </td>
                                                    <td>
                                                        <a onClick={() => viewWithtgstbill(item.id)} className="btn btn-sm btn-success" title='With  Gst Bill View' > <i className="fa fa-eye"></i> View </a>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}
