import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';

function Header() {

    const [date, setDate] = useState(new Date());
    function refreshClock() {
        setDate(new Date());
      }
    useEffect(async () => {
        const timerId = setInterval(refreshClock, 1000);
            return function cleanup() {
            clearInterval(timerId);
            };
    }, [])


    return (
        <div>
            {/* <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__shake" src="../dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
        </div> */}

            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                    </li>
                    
                </ul>
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    
                    
                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                            <i className="fas fa-expand-arrows-alt" />
                        </a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                            <i className="fas fa-th-large" />
                        </a>
                    </li> */}
                    <li className="nav-item">
                        <a  className="nav-link" href="javascript:history.go(-1)">
                            <i className="fas fa-backward" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a  className="nav-link" href="javascript:history.go(+1)">
                            <i className="fas fa-forward" />
                        </a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link  btn time-btn btn-sm"  href="#" role="button" style={{color:"white"}}>
                        <b>{date.toLocaleString()}</b>
                        </a>
                    </li> */}
                </ul>
            </nav>
        </div>
    )
}
export default Header