import React, { useEffect, useState, useRef } from 'react'
import { useParams} from "react-router-dom";
import '.././App.css';
import Moment from 'moment';
import Swal from 'sweetalert2';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Viewcustledger() {
    let { custid } = useParams();
    const login_id = user['id'];
    const [LedgerData, setLedgerData] = useState([]);
    const [searchvalue, setsearchvalue] = useState("");
    useEffect(async () => {
        getLedgerData();
        if (searchvalue=='') {
            getLedgerData();
        }
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getLedgerData(){
        let result = await fetch(""+user.api+"api/get_customer_ledger/" + custid);
        result = await result.json();
        setLedgerData(result);
    }
    

    // ================================================================================================================================================

    function printReport(){
        var content = document.getElementById("reportprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br/>
                <section className="content">
                    <div className="container-fluid"  >
                    <button className="btn btn-success btn-sm" onClick={printReport} style={{marginTop:'10px'}}>Print Ledger</button>
                        <button className="btn btn-secondary float-6" >|| Customer Ledgers||</button><br/><br/><br/>
                        <div className="card card-primary card-outline ex1">
                       
                        <div className="card-body table-responsive p-0" id='reportprint'>
                        <center><h1 style={{color:"red"}}>Customer Ledgers</h1></center>
                            <hr/>
                                <table className="table table-bordered table-hover table-striped table-head-fixed" style={{width:"100%"}}>
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Payment From</th>
                                            <th>Pay Date</th>
                                            <th>Total</th>
                                            <th>Discount</th>
                                            <th>Paid Amount</th>
                                            <th>Remaining</th>
                                            <th>Pay Type</th>
                                            {/* <th width="110">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            LedgerData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.payment_from}</td>
                                                    <td>{Moment(item.pay_date).format('DD-MMM-YYYY')}</td>
                                                    <td>{item.total_amount}</td>
                                                    <td>{item.other1}</td>
                                                    <td>{item.current_paid}</td>
                                                    <td>{item.remaining}</td>
                                                    <td>{item.pay_type}</td>
                                                    {/* <td width="100">
                                                       <a   className="btn btn-sm btn-danger" > <i className="fa fa-trash"></i></a>
                                                    </td> */}
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <iframe id="ifmcontentstoprint" style={{height: 0, width: 0, position: 'absolute'}} />
                                      
            {/* ============================================= modal start ================================================= */}
        </div>
    )
}
