import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import Moment from 'moment';


let user = JSON.parse(localStorage.getItem('user_info'));


export default function Purchase() {
    const login_id = user['id'];
    
    const [ItemData, setItemData] = useState([]);
    const [dealerData, setDealerData] = useState([]);

    const [itemname, setitemname] = useState("");
    const [compname, setcompname] = useState("");
    const [saleprice, setsaleprice] = useState(0);
    const [purchaseprice, setpurchaseprice] = useState(0);
    const [unit, setunit] = useState("NOS");
    const [stock, setstock] = useState(0);
    const [itemdate, setitemdate] = useState(Moment().format('DD-MMM-YYYY'));
    const [hsncode, sethsncode] = useState("");
    const [gstamount, setgstamount] = useState(0);

    const [dealername, setdealername] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [address, setaddress] = useState("");
    const [state, setstate] = useState("Maharashtra");
    const [gstno, setgstno] = useState("");
    const [dealerdate, setdealerdate] = useState(Moment().format('YYYYY-MMM-DD'));

    const [inputList, setInputList] = useState([{ item_id: "", item: "", size: "", qty: "1", rate: "0", total: "", discount: "0", gst_no: "", gstamount: "", taxable: "" }]);
    let [qtytotal, setqtytotal] = useState(1);
    let [ratetotal, setratetotal] = useState(0);
    let [alltotal, setalltotal] = useState(0);
    let [distotal, setdistotal] = useState(0);
    let [gsttotal, setgsttotal] = useState(0);
    let [taxabletotal, settaxabletotal] = useState(0);
    let [grandtotal, setgrandtotal] = useState(0);
    let [paidamount, setpaidamount] = useState("");
    let [remaining, setremaining] = useState(0);

    const [dealerid, setdealerid] = useState("");
    const [purchaseno, setpurchaseno] = useState("");
    const [purhcaseDate, setpurhcaseDate] = useState("");
    const [paytype, setpaytype] = useState("");

    const handleInputChange = async(e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        
        list[index][name] = value;
        const total_amount = list[index]['total'] = list[index]['qty'] * list[index]['rate'] - list[index]['discount'];
        const gstamount = (total_amount * list[index]['gst_no'])/100;
        list[index]['gstamount'] = gstamount;
        list[index]['taxable'] = gstamount + total_amount;
        setInputList(list);
        caltotal();
    };

    const getSize = async(e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        let sresult = await fetch(""+user.api+"api/get_single_items/" + value);
        sresult = await sresult.json();
        if (sresult.size=='' || sresult.length==0) {
        list[index]['size'] = '';
        }else{
        list[index]['size'] = sresult.size+ ' / '+sresult.id;
        list[index]['item_id'] = sresult.id;
        list[index]['gst_no'] = sresult.gst_no;
        }

        const total_amount = list[index]['total'] = list[index]['qty'] * list[index]['rate'] - list[index]['discount'];
        const gstamount = (total_amount * list[index]['gst_no'])/100;
        list[index]['gstamount'] = gstamount;
        list[index]['taxable'] = gstamount + total_amount;
        setInputList(list);
        caltotal();
    };

    const handleRemoveClick = index => {
        const list = [...inputList];
        qtytotal  = qtytotal - list[index]['qty'];
        ratetotal  = ratetotal - list[index]['rate'];
        alltotal  = alltotal - list[index]['total'];
        distotal  = distotal - list[index]['discount'];
        gsttotal  = gsttotal - list[index]['gstamount'];
        taxabletotal  = taxabletotal - list[index]['taxable'];

        setqtytotal(qtytotal);
        setratetotal(ratetotal);
        setalltotal(alltotal);
        setdistotal(distotal);
        setgsttotal(gsttotal);
        settaxabletotal(taxabletotal);
        list.splice(index, 1);
        setInputList(list);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { item_id: "", item: "", size: "", qty: "1", rate: "0", total: "", discount: "0", gst_no: "", gstamount: "", taxable: "" }]);
        caltotal();
    };

   
    const caltotal = () => {
        const list = [...inputList];
        let r1qtytotal = 0;
        let r1ratetotal = 0;
        let r1alltotal = 0;
        let r1distotal = 0;
        let r1gsttotal = 0;
        let r1taxabletotal = 0;
        let grantotal = 0;
        let paid = 0;
        let remaining = 0;
        for (let ri = 0; ri < list.length; ri++) {
            r1qtytotal = parseFloat(r1qtytotal) + parseFloat(inputList[ri]['qty']);
            r1ratetotal = parseFloat(r1ratetotal) + parseFloat(inputList[ri]['rate']);
            r1alltotal = parseFloat(r1alltotal) + parseFloat(inputList[ri]['total']);
            r1distotal = parseFloat(r1distotal) + parseFloat(inputList[ri]['discount']);
            r1gsttotal = parseFloat(r1gsttotal) + parseFloat(inputList[ri]['gstamount']);
            r1taxabletotal = parseFloat(r1taxabletotal) + parseFloat(inputList[ri]['taxable']);
        }
        setqtytotal(r1qtytotal);
        setratetotal(r1ratetotal);
        setalltotal(r1alltotal);
        setdistotal(r1distotal);
        setgsttotal(r1gsttotal);
        settaxabletotal(r1taxabletotal);
        setgrandtotal(r1taxabletotal);
    }
    function paidtotal(e) {
        const { value } = e.target;
        let remaining = grandtotal - value;
        if (remaining < 0) {
            Swal.fire('Sorry!', 'Remaining Amount Should not be less than 0', 'error')
            setremaining(0);
            document.getElementById('paidamount').value = "";
        }else{
            setpaidamount(value);
            setremaining(remaining);
        }
    }
    useEffect(async () => {
        getDealers();
        getItems();
    }, [])
    // ::::::::::::::::::::::::::::::::::::::::::::::::::: GET DEALERS DATA FUNCTION STATR ::::::::::::::::::::::::::::::::::::::

    async function getDealers(){
        let result = await fetch(""+user.api+"api/get_dealers/" + login_id);
        result = await result.json();
        setDealerData(result);

        let result1 = await fetch(""+user.api+"api/max_purchase_no");
        result1 = await result1.json();
        setpurchaseno(result1);
    }

    async function getItems(){
        let result = await fetch(""+user.api+"api/get_items/" + login_id);
        result = await result.json();
        setItemData(result);
    }
    
    // ::::::::::::::::::::::::::::::::::::::::::::::::::: INSERT ITEMS CODE START::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function insDealer() {
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('dealername', dealername);
        form_data.append('mobile', mobile);
        form_data.append('email', email);
        form_data.append('address', address);
        form_data.append('state', state);
        form_data.append('gstno', gstno);
        form_data.append('dealerdate', dealerdate);
        if (dealername == '') {
            Swal.fire('Sorry!', 'Please Fill Dealer Name', 'error')
        }
        else {
            let result = await fetch(""+user.api+"api/insert_dealers", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Dealer Information  Successfully Stored ', 'success');
                getDealers();setdealername(""); setmobile(""); setemail(""); setaddress(""); setstate("NOS"); setgstno(0); setdealerdate("");
            } else {
                Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
            }
        }
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::: START INSERT PRODUCT CODE ::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function insProduct() {
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('itemname', itemname);
        form_data.append('compname', compname);
        form_data.append('saleprice', saleprice);
        form_data.append('purchaseprice', purchaseprice);
        form_data.append('unit', unit);
        form_data.append('stock', stock);
        form_data.append('itemdate', itemdate);
        form_data.append('hsncode', hsncode);
        form_data.append('gstamount', gstamount);
        if (itemname == '' ) {
            Swal.fire('Sorry!', 'Please Fill Some Details', 'error')
        }
        else {
            let result = await fetch(""+user.api+"api/insert_product", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Item Successfully Stored ', 'success');
                setitemname(""); setcompname(""); setsaleprice(""); setpurchaseprice(""); setunit("NOS"); setstock(0); setitemdate(""); sethsncode(""); setgstamount(0);
            } else {
                Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
            }
        }
    }
    //:::::::::::::::::::::::::::::::::::::::::::::::::::::: INSERT DATA CODE (MAIN CODE)

    async function storeData() {
        const list = [...inputList];
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('list', list);
        form_data.append('dealerid', dealerid);
        form_data.append('alltotal', alltotal);
        form_data.append('distotal', distotal);
        form_data.append('gsttotal', gsttotal);
        form_data.append('purchaseno', purchaseno);
        form_data.append('purhcaseDate', purhcaseDate);
        form_data.append('grandtotal', grandtotal);
        form_data.append('remaining', remaining);
        form_data.append('paidamount', paidamount);
        form_data.append('paytype', paytype);
        if (dealerid == '' || purhcaseDate == ''|| list.length == '') {
            Swal.fire('Plz Fill all Belows Data!', 'Dealer Name, Purchase Date', 'error')
        }
        else {
            for (let xx = 0; xx < list.length; xx++) {
                const form_data1 = new FormData();
                form_data1.append('purchase_id', purchaseno);
                form_data1.append('item_id', list[xx]['item_id']);
                form_data1.append('size',  list[xx]['size']);
                form_data1.append('quantity', list[xx]['qty']);
                form_data1.append('rate', list[xx]['rate']);
                form_data1.append('total_amount', list[xx]['total']);
                form_data1.append('discount1', list[xx]['discount']);
                form_data1.append('gst',  list[xx]['gst_no']);
                form_data1.append('gst_amount', list[xx]['gstamount']);
                form_data1.append('tacable_amount', list[xx]['taxable']);
                form_data1.append('login_id', login_id);

                let result1 = await fetch(""+user.api+"api/insert_purchaseitems", { method: 'POST', body: form_data1 });
                result1 = await result1.json();
                console.log(result1);
            }
            let result = await fetch(""+user.api+"api/insert_purchase", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Purchase Data Successfully Saved ', 'success');
                window.location.reload(true);
            } else {
                Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
            }
        }
    }


    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#add-dealer">Add New Dealer</button>
                        <button className="btn btn-secondary float-6" >|| Purchase Management ||</button>
                        &nbsp;&nbsp;<button className="btn btn-success btn-sm" data-toggle="modal" data-target="#add-items">Add New Items</button><br /><br />
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <form id="form" >
                                    <div className='row '>
                                        <div className='col-sm-5'>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Select Dealer Id  </label> </div>
                                                <div className="col-sm-7">
                                                <input required="true" list="custlist" className='form-control'value={dealerid} onChange={(e) => setdealerid(e.target.value)} />
                                                <datalist id="custlist">
                                                <option></option>
                                                {dealerData.map((option) => (
                                                        <option value={option.id}  key={option.id}>{option.dealer_name}</option>
                                                        ))}
                                                </datalist>
                                                    
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            {/* <div className='row form-group'>
                                                <div className="col-sm-3"><label>Dealer ID </label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' readOnly/>
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div> */}
                                        </div>
                                        <div className='col-sm-2 '></div>
                                        <div className='col-sm-5 '>
                                            <div className='row form-group'>
                                                <div className="col-sm-4"><label>Purchase No </label> </div>
                                                <div className="col-sm-8">
                                                    <input type="text" className="form-control" readOnly value={purchaseno} onChange={(e) => setpurchaseno(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-4"><label>Purchase Date </label> </div>
                                                <div className="col-sm-8">
                                                    <input type="date" className="form-control" value={purhcaseDate} onChange={(e) => setpurhcaseDate(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row form-group'>
                                        <table className='customers'>
                                            <thead>
                                                <tr>
                                                    <th width="55"><label>Action</label></th>
                                                    <th style={{textAlign: 'center'}} width="280"><label>Item</label></th>
                                                    <th width="80"><label>Qty</label></th>
                                                    <th width="80"><label>SQFT</label></th>
                                                    {/* <th><label>Size</label></th> */}
                                                    {/* <th><label>Rate</label></th> */}
                                                    {/* <th width="80"><label>Discount</label></th> */}
                                                    {/* <th width="150"><label>Total </label></th> */}
                                                    {/* <th width="70"><label>GST (%)</label></th>
                                                    <th><label>GST Amnt</label></th>
                                                    <th><label>Taxable Amnt</label></th> */}
                                                </tr>
                                            </thead>
                                            <tbody id="tbd">
                                                {inputList.map((x, i) => {
                                                    return (
                                                        <tr key={i}>
                                                             <td>
                                                             &nbsp;&nbsp;{inputList.length !== 1 && <a  title='Remove Current Row' className='btn btn-xs btn-danger' onClick={() => handleRemoveClick(i)}><i className='fa fa-trash'></i></a>}
                                                                &nbsp;{inputList.length - 1 === i && <a title='Add One More Row' className='btn btn-xs btn-info' onClick={handleAddClick}><i className='fa fa-plus'></i></a>}
                                                            </td>
                                                            
                                                            <td>
                                                                <input type="hidden" required="true" className="form-control" name="item_id" value={x.item_id}  readOnly />
                                                                <input required="true" list="itemslist" className='form-control' onKeyUp={e => getSize(e, i)} value={x.item} name="item" onChange={e => handleInputChange(e, i)} />
                                                                <datalist id="itemslist">
                                                                <option></option>
                                                                {ItemData.map((option) => (
                                                                        <option value={option.name}  key={option.id}/>
                                                                        ))}
                                                                </datalist>
                                                            </td>
                                                            <td>
                                                                <input required="true" type="text" className="form-control" name="qty" value={x.qty}  onChange={e => handleInputChange(e, i)} placeholder='Qty' />
                                                            </td>
                                                            {/* <td>
                                                                <input type="text" className="form-control" name="size" value={x.size}  readOnly />
                                                            </td> */}
                                                           
                                                            <td>
                                                                <input required="true" type="text" className="form-control" name="rate" value={x.rate}  onChange={e => handleInputChange(e, i)} placeholder='Enter Sqft' />
                                                            </td>
                                                            {/* <td>
                                                                <input type="text" className="form-control" name="discount" value={x.discount}  onChange={e => handleInputChange(e, i)} placeholder='Dis' />
                                                            </td> */}
                                                            {/* <td>
                                                                <input type="text" className="form-control" name="total" value={x.total}  readOnly />
                                                            </td> */}

                                                            {/* <td>
                                                                <input type="text" className="form-control" name="gst_no" value={x.gst_no}  readOnly />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" name="gstamount"  value={x.gstamount}  readOnly />
                                                            </td>
                                                            <td>
                                                                <input required="true" type="text" className="form-control" name="taxable" value={x.taxable}   readOnly />
                                                            </td> */}
                                                           
                                                        </tr>

                                                    );
                                                })}
                                                <tr>
                                                    <td colSpan={2}></td>
                                                    <td><input type="text" readOnly className="form-control" value={qtytotal} name="qty" /> </td>
                                                    <td><input type="text" readOnly className="form-control" value={ratetotal}  name="rate" /> </td>
                                                    {/* <td> <input type="text" readOnly className="form-control" value={distotal} name="discount" /> </td> */}
                                                    {/* <td> <input type="text" readOnly className="form-control" value={alltotal} name="total" /> </td> */}
                                                    {/* <td> </td>
                                                    <td><input type="text" readOnly className="form-control" value={gsttotal} name="gstamount" /></td>
                                                    <td> <input type="text" readOnly className="form-control" value={taxabletotal} name="taxable" /></td>
                                                    <td></td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                                    </div>
                                    {/* <div className='row '>
                                        <div className='col-sm-6'>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Grand Total </label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' readOnly value={grandtotal} />
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Paid</label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control'  name='paidamount'  id='paidamount' placeholder='Enter Paid Amount' onChange={e => paidtotal(e)}  />
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Payment Type</label> </div>
                                                <div className="col-sm-7">
                                                    <select className='form-control' value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                                                        <option>-- Select Payment Type --</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Bank">Bank</option>
                                                        <option value="RTGS">RTGS</option>
                                                        <option value="Online Pay">Online Pay</option>
                                                    </select>
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Remaining</label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' value={remaining} readOnly/>
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                        </div>

                                    </div><hr /> */}
                                    <center><button type='button' onClick={storeData} className='btn btn-info'>Save Purchase</button></center>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="add-dealer">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add New Dealer</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>  Dealer Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={dealername} onChange={(e) => setdealername(e.target.value)} className="form-control" placeholder="Enter  Dealer Name ..." />
                                </div>
                                <div className="col-sm-2"><label>Mobile</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" placeholder="Enter  Dealer Mobile ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>Email</label> </div>
                                <div className="col-sm-4">
                                    <input type="email" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" placeholder="Enter Dealer Email Id ..." />
                                </div>
                                <div className="col-sm-2"><label>Address</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" placeholder="Enter Dealer Address ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  State</label> </div>
                                <div className="col-sm-4">
                                    <select className="form-control" value={state} onChange={(e) => setstate(e.target.value)}>
                                        <option>--Select State--</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                        <option value="Daman and Diu">Daman and Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Lakshadweep">Lakshadweep</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>GST No</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={gstno} onChange={(e) => setgstno(e.target.value)} className="form-control" placeholder="Enter Dealer GST No ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Dealer Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date" value={dealerdate} onChange={(e) => setdealerdate(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={insDealer} className="btn btn-primary" data-dismiss="modal">Save Dealer</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade" id="add-items">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Items</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>  Item Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={itemname} onChange={(e) => setitemname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                                </div>
                                <div className="col-sm-2"><label>Company Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={compname} onChange={(e) => setcompname(e.target.value)} className="form-control" placeholder="Enter  Company Name ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Sale Price</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={saleprice} onChange={(e) => setsaleprice(e.target.value)} className="form-control" placeholder="Enter Sale Price ..." />
                                </div>
                                <div className="col-sm-2"><label>Purchase Price</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={purchaseprice} onChange={(e) => setpurchaseprice(e.target.value)} className="form-control" placeholder="Enter  Purchase Price ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Select Unit</label> </div>
                                <div className="col-sm-4">
                                    <select className="form-control" value={unit} onChange={(e) => setunit(e.target.value)}>
                                        <option>--Select Unit--</option>
                                        <option value="NOS">NOS</option>
                                        <option value="SQFT">SQFT</option>
                                        <option value="BRASS">BRASS</option>
                                        <option value="HBRASS">HALF BRASS</option>
                                        <option value="BAG">BAG</option>
                                        <option value="MM">MM</option>
                                        <option value="PCS">PCS</option>
                                        <option value="TON">TON</option>
                                        <option value="KG">KG</option>
                                        <option value="MTR">MTR</option>
                                        <option value="INCH">INCH</option>
                                        <option value="FEET">FEET</option>
                                        <option value="NONE">NONE</option>
                                        <option value="FREE SIZE">FREE SIZE</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>Stock</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={stock} onChange={(e) => setstock(e.target.value)} className="form-control" placeholder="Enter  Company Name ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Item Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date" value={itemdate} onChange={(e) => setitemdate(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-2"><label>HSN Code</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={hsncode} onChange={(e) => sethsncode(e.target.value)} className="form-control" placeholder="Enter  HSN Code ..." />
                                </div>
                            </div><br />

                            <div className="row">
                                <div className="col-sm-2"><label> GST</label> </div>
                                <div className="col-sm-4">
                                    <select className="form-control" value={gstamount} onChange={(e) => setgstamount(e.target.value)}>
                                        <option>--Select GST Percentage--</option>
                                        <option value="0">0</option>
                                        <option value="5">5</option>
                                        <option value="12">12</option>
                                        <option value="18">18</option>
                                        <option value="28">28</option>
                                    </select>
                                </div>

                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={insProduct} className="btn btn-primary" data-dismiss="modal">Save Product</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
