import React, { useEffect, useState } from 'react';
import {  Link } from "react-router-dom";
import '.././App.css';
import Header from '../compoenets/Header';
function Register() {
    useEffect(() => {
        if (localStorage.getItem('user_info')) {
            window.location.href ="/home"
        }
    })
    const [name, setName] = useState("")
    const [counter, setCounter] = useState("")
    const [mobile, setMobile] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    async function signup() {
        let item = { name, counter, mobile, username, password }
        console.log(item);
        let result = await fetch("https://shreedbackend.blueviperstechnology.com/api/register", {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json()
        localStorage.setItem("user_info", JSON.stringify(result))
        window.location.href = "/home";
    }

    return (
        <>
        <body class="hold-transition register-page">
            <div className="register-box">
                <div className="register-logo">
                <a href="#"><b>ASC  </b>REGISTER</a>
                </div>
                <div className="card">
                    <div className="card-body register-card-body">
                        <p className="login-box-msg">Register a new membership</p>
                            <div className="input-group mb-3">
                                <select className="form-control"  value={counter} onChange={(e)=>setCounter(e.target.value)}>
                                    <option value="C1">C1</option>
                                    <option value="C2">C2</option>
                                    <option value="C3">C3</option>
                                    <option value="C4">C4</option>
                                    <option value="C5">C5</option>
                                    <option value="C6">C6</option>
                                    <option value="C7">C7</option>
                                    <option value="C8">C8</option>
                                </select>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-table" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" placeholder="Full name" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" value={mobile} onChange={(e)=>setMobile(e.target.value)} placeholder="Mobile No" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-phone" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="email" className="form-control"  value={username} onChange={(e)=>setUsername(e.target.value)}  placeholder="Email / Username " />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder=" password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* /.col */}
                                <div className="col-12">
                                    <button type="submit" onClick={signup} className="btn btn-primary btn-block">Register</button>
                                </div>
                                {/* /.col */}
                            </div><br />
                        <a href="/login" className="text-center">I already have a membership</a>
                    </div>
                    {/* /.form-box */}
                </div>{/* /.card */}
            </div>
        </body>

        </>
    )
}

export default Register;