import React, { useEffect, useState, useRef } from 'react'
import { useParams } from "react-router-dom";
import Moment from 'moment';
import '.././App.css';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function ViewWithoutgst() {
    const login_id = user['id'];
    let { id } = useParams();
    const [SaleData, setSaleData] = useState([]);
    const [SaleData1, setSaleData1] = useState([]);
    const [LedgerData, setLedgerData] = useState([]);
    const [accData, setaccData] = useState([]);
    const [searchvalue, setsearchvalue] = useState("");
    useEffect(async () => {
        getSaleData();
        if (searchvalue=='') {
            getSaleData();
        }
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getSaleData(){

        let result3 = await fetch(""+user.api+"api/get_customeraccount/" + login_id);
        result3 = await result3.json();
        setaccData(result3);
        result3 = Object.values(result3);
        console.log(result3);

        
        let result = await fetch(""+user.api+"api/get_withoutgstsale/" + login_id);
        result = await result.json();
        setSaleData(result);

        let result1 = await fetch(""+user.api+"api/get_withgstsale/" + login_id);
        result1 = await result1.json();
        setSaleData1(result1);

        let result2 = await fetch(""+user.api+"api/get_customer_ledger/" + id);
        result2 = await result2.json();
        setLedgerData(result2);
        result2 = Object.values(result2);

        
    }
    // ::::::::::::::::::::::::::::::::::::::::::::::: GET SEARCH DATA :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

        async function getSearchData() {
            const form_data = new FormData();
            form_data.append('searchvalue', searchvalue);
            form_data.append('login_id', login_id);
            
            let result = await fetch(""+user.api+"api/purchase_search", { method: 'POST', body: form_data });
            result = await result.json();
            setSaleData(result);
    }

    async function viewSale(id) {
        window.location.href = '/view-withoutgst-sale-item/'+ id +'';
    }
    async function viewSale1(id) {
        window.location.href = '/view-withgst-sale-item/'+ id +'';
    }
    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br/>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| All Customer Report ||</button><br/><br/><br/>
                        <div className="card card-primary card-outline ">
                        <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <td className='head'>Customer Name</td>
                                            <td className='head'>Customer Mobile</td>
                                            <td className='head'>Total Amount</td>
                                            <td className='head'>Paid </td>
                                            <td className='head'>Remaining</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                     {
                                        accData.map((item, num = 1) =>
                                            item[0]==id?
                                            <tr key={num+1}>
                                                <td className='head1'>{item[3][0]['cust_name']}</td>
                                                <td className='head1'>{item[3][0]['mobile']}</td>
                                                <td className='head1'>{item[1]}</td>
                                                <td className='head1'>{item[2]}</td>
                                                <td className='head1'>{item[1] - item[2]}</td>
                                            </tr>
                                            :null
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h4><b>All Sale Report</b></h4>
                        <div className="card card-primary card-outline ">
                        {/* <div className="row p-1">
                            &nbsp;&nbsp;<input onKeyUp={getSearchData} type="search"  value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} className='form-control col-sm-4' placeholder='Enter Purchase Details......'/>&nbsp;
                        </div> */}
                        <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Customer Name</th>
                                            <th>Sale Date</th>
                                            <th>Total </th>
                                            <th>Discount</th>
                                            <th>Total</th>
                                            <th>Paid </th>
                                            <th>Remaining</th>
                                            <th width="110">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            SaleData.map((item, num = 1) =>
                                             item.custid == id?
                                                <tr key={num+1}>
                                                    <td><b>Without Gst Sale</b></td>
                                                    <td>{item.cust_name}</td>
                                                    <td>{Moment(item.sale_date).format('DD-MMM-YYYY')}</td>
                                                    <td>{item.total_amount}</td>
                                                    <td>{item.distotal1}</td>
                                                    <td>{item.grandtotal}</td>
                                                    <td>{item.paid}</td>
                                                    <td>{item.remaining}</td>
                                                    <td width="100">
                                                        <a  onClick={() => viewSale(item.id)}  className="btn btn-sm btn-success" > <i className="fa fa-eye"></i></a>
                                                    </td>
                                                </tr>
                                             :
                                             null
                                            )}

{
                                            SaleData1.map((item, num = 1) =>
                                             item.custid == id?
                                                <tr key={num+1}>
                                                    <td><b>With Gst Sale</b></td>
                                                    <td>{item.cust_name}</td>
                                                    <td>{Moment(item.sale_date).format('DD-MMM-YYYY')}</td>
                                                    <td>{item.total_amount}</td>
                                                    <td>{item.distotal1}</td>
                                                    <td>{item.grandtotal}</td>
                                                    <td>{item.paid}</td>
                                                    <td>{item.remaining}</td>
                                                    <td width="100">
                                                        <a  onClick={() => viewSale1(item.id)}  className="btn btn-sm btn-success" > <i className="fa fa-eye"></i></a>
                                                    </td>
                                                </tr>
                                             :
                                             null
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h4><b>Customer Ledgers</b></h4>
                        <div className="card card-primary card-outline ">
                        <div className="card-body table-responsive p-0" >
                                 <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Payment From</th>
                                            <th>Pay Date</th>
                                            <th>Total</th>
                                            <th>Paid Amount</th>
                                            <th>Remaining</th>
                                            <th>Pay Type</th>
                                            {/* <th width="110">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            LedgerData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.payment_from}</td>
                                                    <td>{Moment(item.pay_date).format('DD-MMM-YYYY')}</td>
                                                    <td>{item.total_amount}</td>
                                                    <td>{item.current_paid}</td>
                                                    <td>{item.remaining}</td>
                                                    <td>{item.pay_type}</td>
                                                    {/* <td width="100">
                                                       <a   className="btn btn-sm btn-danger" > <i className="fa fa-trash"></i></a>
                                                    </td> */}
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}
        </div>
    )
}
