import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import Moment from 'moment';
import Salereport from './Salereport';
import Purchasereport from './Purchasereport';

let user = JSON.parse(localStorage.getItem('user_info'));

export default function Spreport() {
    const login_id = user['id'];
    
    const [reportData1, setreportData1] = useState([]);
    const [reportData2, setreportData2] = useState([]);
    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");
    const [type, settype] = useState("");
    const [custtype, setcusttype] = useState("");
    const [reporttype, setreporttype] = useState("");
    const [alltotal, setalltotal] = useState(0);
    const [allpaid, setallpaid] = useState(0);
    const [allrem, setallrem] = useState(0);
   
   
    async function getReport(){
        if (type == '' || toDate=='' || fromDate=='') {
            Swal.fire('Sorry!', 'All fields are required', 'error')
        } 
        else {
            if (type=='Sale') {
              setreporttype(type);
              setcusttype('CUSTOMER');
              const form_data = new FormData();
              form_data.append('fromDate', fromDate);
              form_data.append('toDate', toDate);
              let result = await fetch(""+user.api+"api/getSaleReport", { method: 'POST', body: form_data });
              result = await result.json();
              setreportData1(result[0])
              setreportData2(result[1])
              setalltotal(result[2])
              setallpaid(result[3])
              setallrem(result[4])
            }
            else{
                setreporttype(type);
                setcusttype('DEALER');
                const form_data = new FormData();
                form_data.append('fromDate', fromDate);
                form_data.append('toDate', toDate);
                let result = await fetch(""+user.api+"api/getPurchaseReport", { method: 'POST', body: form_data });
                result = await result.json();
                setreportData1(result[0])
                setreportData2(result[1])
                setalltotal(result[2])
                setallpaid(result[3])
                setallrem(result[4])
            }
        }
    }

    function printReport(){
        var content = document.getElementById("reportprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| Sale / Purchase Report ||</button><br /><br /><br/>
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <div className='row '>
                                    <div className='col-sm-12'>
                                        <div className='row form-group'>
                                            <div className="col-sm-1"><label>Select Type</label> </div>
                                            <div className="col-sm-2">
                                                <select className='form-control'  value={type} onChange={(e) => settype(e.target.value)}>
                                                    <option></option>
                                                    <option value='Sale'>Sale</option>
                                                    <option value='Purchase'>Purchase</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-1"><label>From Date </label> </div>
                                            <div className="col-sm-2">
                                                <input type="date" className='form-control' value={fromDate} onChange={(e) => setfromDate(e.target.value)} />
                                            </div>
                                            <div className="col-sm-1"><label>To Date </label> </div>
                                            <div className="col-sm-2">
                                                <input type="date" className='form-control'value={toDate} onChange={(e) => settoDate(e.target.value)} />
                                            </div>
                                            <div className="col-sm-2">
                                            <center><button type='button' onClick={getReport} className='btn btn-info'>Get Report</button></center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-primary card-outline " id='reportprint'>
                            <br/>
                                <center><h1 style={{color:"red"}}>{reporttype} Report</h1></center>
                            <hr/>
                            <div className="card-body table-responsive p-0" >
                            <table className="table table-bordered table-hover table-striped table-head-fixed" style={{width:"100%"}}>
                                <thead>
                                    <tr >
                                        <td  style={{padding:"10px"}}><b>{custtype} NAME</b></td>
                                        <td  style={{padding:"10px"}}><b>{custtype} MOBILE</b></td>
                                        <td  style={{padding:"10px"}}><b>DATE </b></td>
                                        <td  style={{padding:"10px"}}><b>TOTAL </b></td>
                                        <td  style={{padding:"10px"}}><b>PAID  </b></td>
                                        <td  style={{padding:"10px"}}><b>REMAINING</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                   {
                                       reportData1.map((item, num = 1) => 
                                        <tr key={num+1}>
                                             {
                                                reporttype=='Sale'?
                                                <td style={{padding:"10px"}}>{item.cust_name}</td>
                                                :
                                                <td style={{padding:"10px"}}>{item.dealer_name}</td>
                                            }
                                            <td style={{padding:"10px"}}>{item.mobile}</td>
                                            <td style={{padding:"10px"}}>{Moment(item.sale_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.grandtotal}</td>
                                            <td style={{padding:"10px"}}>{item.paid}</td>
                                            <td style={{padding:"10px"}}>{item.remaining}</td>
                                        </tr>   
                                    )}
                                    {
                                       reportData2.map((item, num = 1) => 
                                        <tr key={num+1}>
                                            <td style={{padding:"10px"}}>{item.cust_name}</td>
                                            <td style={{padding:"10px"}}>{item.mobile}</td>
                                            <td style={{padding:"10px"}}>{Moment(item.sale_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.grandtotal}</td>
                                            <td style={{padding:"10px"}}>{item.paid}</td>
                                            <td style={{padding:"10px"}}>{item.remaining}</td>
                                        </tr>   
                                    )}
                                </tbody>
                            </table>
                            <hr/>
                             <div style={{padding:"5px"}}>
                                <h4 style={{color:"blue"}}>All Total Amount - <b>{alltotal} /-</b></h4>
                                <h4 style={{color:"green"}}>All Paid Amount - <b>{allpaid} /-</b></h4>
                                <h4 style={{color:"red"}}>All Remaining Amount - <b>{allrem} /-</b></h4>
                            </div>
                            </div>
                        </div>
                        <center><button className='btn btn-info' onClick={printReport}>Print Report</button></center>
                       <iframe id="ifmcontentstoprint" style={{height: 0, width: 0, position: 'absolute'}} />

                    </div>
                </section>
            </div>

           
        </div>
    )
}
