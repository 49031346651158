import React, { useEffect, useState, useRef } from 'react'
import { useParams} from "react-router-dom";
import '.././App.css';
import Swal from 'sweetalert2';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function ViewWithgstitem() {
    let { id } = useParams();
    const login_id = user['id'];
    const [SaleItemData, setSaleItemData] = useState([]);
    const [searchvalue, setsearchvalue] = useState("");
    useEffect(async () => {
        getSaleitemData();
        if (searchvalue=='') {
            getSaleitemData();
        }
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getSaleitemData(){
        let result = await fetch(""+user.api+"api/get_withgstsale_items/" + id);
        result = await result.json();
        setSaleItemData(result);
    }
    
    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br/>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| View With GST Sale Items||</button><br/><br/><br/>
                        <div className="card card-primary card-outline ex1">
                       
                        <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th><label>Item</label></th>
                                            <th><label>Size / Id</label></th>
                                            <th><label>Hght</label></th>
                                            <th><label>Wdth</label></th>
                                            <th><label>Qty</label></th>
                                            <th><label>Sqft</label></th>
                                            <th><label>Rate</label></th>
                                            <th><label> Total </label></th>
                                            <th><label> GST  %</label></th>
                                            <th><label> GSt Amnt </label></th>
                                            <th><label>Discount</label></th>
                                            <th><label>Fitting  </label></th>
                                            <th><label>DTP Charges </label></th>
                                            <th><label>All Total</label></th>
                                            {/* <th width="110">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            SaleItemData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.height}</td>
                                                    <td>{item.width}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.sqft * item.quantity}</td>
                                                    <td>{item.rate}</td>
                                                    <td>{item.total_amount * item.quantity} </td>
                                                    <td>{item.gst} %</td>
                                                    <td>{item.gstamount}</td>
                                                    <td>{item.discount}</td>
                                                    <td>{item.other_charge2}</td>
                                                    <td>{item.other_charge3}</td>
                                                    <td>{item.taxable_amount}</td>
                                                    {/* <td width="100">
                                                       <a   className="btn btn-sm btn-danger" > <i className="fa fa-trash"></i></a>
                                                    </td> */}
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}
        </div>
    )
}
