import React, { useEffect, useState, useRef } from 'react'
import { useParams} from "react-router-dom";
import '.././App.css';
import Swal from 'sweetalert2';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Viewpurchase() {
    let { id } = useParams();
    const login_id = user['id'];
    const [PurItemData, setPurItemData] = useState([]);
    const [searchvalue, setsearchvalue] = useState("");
    useEffect(async () => {
        getPuritemData();
        if (searchvalue=='') {
            getPuritemData();
        }
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getPuritemData(){
        let result = await fetch(""+user.api+"api/get_purchase_items/" + id);
        result = await result.json();
        setPurItemData(result);
        console.log(result);
    }
    
    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br/>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| View Purchase Items||</button>
                        <a className="btn btn-info btn-sm"  href='/purchase-mgmt'>Add Purchase</a><br/><br/>
                        <div className="card card-primary card-outline ex1">
                       
                        <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Item</th>
                                            <th>Size / Item Id</th>
                                            <th>Quantity</th>
                                            {/* <th>Rate</th> */}
                                            <th>Sqft</th>
                                            {/* <th>Discount</th>
                                            <th>GST</th>
                                            <th>GST Amnt</th>
                                            <th>Taxable Amount</th> */}
                                            {/* <th width="110">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            PurItemData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.rate} Sqft</td>
                                                    {/* <td>{item.total_amount} </td> */}
                                                    {/* <td>{item.discount1}</td>
                                                    <td>{item.gst} %</td>
                                                    <td>{item.gst_amount}</td>
                                                    <td>{item.tacable_amount}</td> */}
                                                    {/* <td width="100">
                                                       <a  className="btn btn-sm btn-danger" > <i className="fa fa-trash"></i></a>
                                                    </td> */}
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}
        </div>
    )
}
