import React, { useEffect, useState, useRef } from 'react'
import { useParams } from "react-router-dom";
import '.././App.css';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Customeroutstandingprint() {
    let { id } = useParams();
    const login_id = user['id'];
    const [Cust, setCust] = useState([]);
    let [alltot, setalltot] =  useState(0);
    let [allpaid, setallpaid] =  useState(0);
    let [alldis, setalldis] =  useState(0);
    let [allrem, setallrem] =  useState(0);
    useEffect(async () => {
        let result = await fetch(""+user.api+"api/get_customeraccount/" + login_id);
        result = await result.json();
        setCust(result[0]);
        result[0] = Object.values(result[0]);
        setalltot((Math.round(result[1] * 100) / 100).toFixed(2));
        setallpaid((Math.round(result[2] * 100) / 100).toFixed(2));
        setalldis((Math.round(result[3] * 100) / 100).toFixed(2));
        let rem = result[1] - result[2] - result[3];
        setallrem((Math.round(rem * 100) / 100).toFixed(2));

        const timer = setTimeout(() => {
        window.addEventListener("load", window.print());
          }, 1000);
          return () => clearTimeout(timer);
    }, [])


    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-primary ">
                            {/* <center>
                            <br/>
                                <h3>All Customer Outstanding</h3>
                            </center>
                            <br/> */}
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <tbody>
                                         <tr>
                                            <td><b>Sr No</b></td>
                                            <td><b>Customer Name</b></td>
                                            <td><b>Customer Mobile</b></td>
                                            <td><b>Total Amount</b></td>
                                            <td><b>Paid </b></td>
                                            <th>Discount </th>
                                            <td><b>Remaining</b></td>
                                        </tr>
                                        {
                                            
                                            Cust.map((item, num = 1) =>
                                                item[1] - item[2]- item[4]===0?
                                                <></>
                                                :
                                                
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item[3][0]['cust_name']}</td>
                                                    <td>{item[3][0]['mobile']}</td>
                                                    <td>{item[1]}</td>
                                                    <td>{item[2]}</td>
                                                    <td>{item[4]}</td>
                                                    <td>{item[1] - item[2]- item[4]}</td>
                                                </tr>
                                                
                                            )}
                                              <tr>
                                                <td colSpan={3}>Total</td>
                                                <td>{alltot}</td>
                                                <td>{allpaid}</td>
                                                <td>{alldis}</td>
                                                <td>{allrem}</td>
                                            </tr>
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </section>

            </div>

            {/* ============================================= modal start ================================================= */}
        </div>
    )
}
