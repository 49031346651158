import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import Moment from 'moment';
import Salereport from './Salereport';
import Purchasereport from './Purchasereport';

let user = JSON.parse(localStorage.getItem('user_info'));

export default function Iereport() {
    const login_id = user['id'];
    
    const [incomereportData1, setincomereportData1] = useState([]);
    const [incomereportData2, setincomereportData2] = useState([]);
    const [expensereportData1, setexpensereportData1] = useState([]);
    const [expensereportData2, setexpensereportData2] = useState([]);
    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");
    const [type, settype] = useState("");
    const [alltincome, setalltincome] = useState(0);
    const [allexpense, setallexpense] = useState(0);
    const [allprofit, setallprofit] = useState(0);
   
   
    async function getReport(){
        if (toDate=='' || fromDate=='') {
            Swal.fire('Sorry!', 'Please Select From Date and To Date', 'error')
        } 
        else {
              const form_data = new FormData();
              form_data.append('fromDate', fromDate);
              form_data.append('toDate', toDate);
              let result = await fetch(""+user.api+"api/getincomeexpensereport", { method: 'POST', body: form_data });
              result = await result.json();
              console.log(result);
              setincomereportData1(result[1])
              setincomereportData2(result[0])
              setexpensereportData1(result[3])
              setexpensereportData2(result[2])
              setalltincome(result[4])
              setallexpense(result[5])
              setallprofit(result[4] - result[5])
        }
    }

    function printReport(){
        var content = document.getElementById("reportprint");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| Income / Expense Report ||</button><br /><br /><br/>
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <div className='row '>
                                    <div className='col-sm-12'>
                                        <div className='row form-group'>
                                            <div className="col-sm-1"><label>From Date </label> </div>
                                            <div className="col-sm-2">
                                                <input type="date" className='form-control' value={fromDate} onChange={(e) => setfromDate(e.target.value)} />
                                            </div>
                                            <div className="col-sm-1"><label>To Date </label> </div>
                                            <div className="col-sm-2">
                                                <input type="date" className='form-control'value={toDate} onChange={(e) => settoDate(e.target.value)} />
                                            </div>
                                            <div className="col-sm-2">
                                            <center><button type='button' onClick={getReport} className='btn btn-info'>Get Report</button></center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-primary card-outline " id='reportprint'>
                            <br/>
                                <center><h1 style={{color:"red"}}>Income Expense Report</h1></center>
                            <hr/>
                            <div className="card-body table-responsive p-0" >
                            <h3>&nbsp;&nbsp;<b>Income Report</b></h3>
                            <table className="table table-bordered table-hover table-striped table-head-fixed" style={{padding:"10px"},{width:"100%"}}>
                                <thead>
                                    <tr >
                                        <td  style={{padding:"10px"}}><b>PAY DATE</b></td>
                                        <td  style={{padding:"10px"}}><b>PAY TYPE</b></td>
                                        <td  style={{padding:"10px"}}><b>AMOUNT </b></td>
                                        <td  style={{padding:"10px"}}><b>DESCREPTION </b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                   {
                                       incomereportData1.map((item, num = 1) => 
                                        <tr key={num+1}>
                                           
                                            <td style={{padding:"10px"}}>{Moment(item.pay_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.pay_type}</td>
                                            <td style={{padding:"10px"}}>{item.amount}</td>
                                            <td style={{padding:"10px"}}>{item.descreption}</td>
                                        </tr>   
                                    )}
                                    {
                                       incomereportData2.map((item, num = 1) => 
                                        <tr key={num+1}>
                                            <td style={{padding:"10px"}}>{Moment(item.pay_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.pay_type}</td>
                                            <td style={{padding:"10px"}}>{item.current_paid}</td>
                                            <td style={{padding:"10px"}}>Paid by {item.cust_name} </td>
                                        </tr>   
                                    )}
                                </tbody>
                            </table>
                            <hr/>
                            <h3>&nbsp;&nbsp;<b>Expense Report</b></h3>
                            <table className="table table-bordered table-hover table-striped table-head-fixed" style={{padding:"10px"},{width:"100%"}}>
                                <thead>
                                    <tr >
                                        <td  style={{padding:"10px"}}><b>PAY DATE</b></td>
                                        <td  style={{padding:"10px"}}><b>PAY TYPE</b></td>
                                        <td  style={{padding:"10px"}}><b>AMOUNT </b></td>
                                        <td  style={{padding:"10px"}}><b>DESCREPTION </b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                   {
                                       expensereportData1.map((item, num = 1) => 
                                        <tr key={num+1}>
                                           
                                            <td style={{padding:"10px"}}>{Moment(item.pay_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.pay_type}</td>
                                            <td style={{padding:"10px"}}>{item.amount}</td>
                                            <td style={{padding:"10px"}}>Pay to {item.name}</td>
                                        </tr>   
                                    )}
                                    {
                                       expensereportData2.map((item, num = 1) => 
                                        <tr key={num+1}>
                                            <td style={{padding:"10px"}}>{Moment(item.pay_date).format('DD-MMM-YYYY')}</td>
                                            <td style={{padding:"10px"}}>{item.pay_type}</td>
                                            <td style={{padding:"10px"}}>{item.current_paid}</td>
                                            <td style={{padding:"10px"}}>Pay to {item.dealer_name} </td>
                                        </tr>   
                                    )}
                                </tbody>
                            </table>
                            <hr/>
                             <div style={{padding:"5px"}}>
                                <h4 style={{color:"red"}}>All Income Amount - {alltincome}<b> /-</b></h4>
                                <h4 style={{color:"red"}}>All Expense Amount - {allexpense}<b> /-</b></h4>
                                <h4 style={{color:"green"}}>All Profit Amount - {allprofit}<b> /-</b></h4>
                            </div>
                            </div>
                        </div>
                        <center><button className='btn btn-info' onClick={printReport}>Print Report</button></center>
                       <iframe id="ifmcontentstoprint" style={{height: 0, width: 0, position: 'absolute'}} />

                    </div>
                </section>
            </div>

           
        </div>
    )
}
