import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Viewpurchase() {
    const login_id = user['id'];
    const [DaccData, setDaccData] = useState([]);
    const [searchvalue, setsearchvalue] = useState("");

    const [dealer_id, setdealer_id] = useState("");
    const [total_amount, settotal_amount] = useState("");
    const [currentpaid, setcurrentpaid] = useState("");
    const [prevpaid, setprevpaid] = useState("");
    const [paiddate, setpaiddate] = useState("");
    const [remaining, setremaining] = useState("");
    const [remaining1, setremaining1] = useState("");
    const [paytype, setpaytype] = useState("");
    useEffect(async () => {
        getDaccountData();
       
    }, [])
   
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getDaccountData(){
        let result = await fetch(""+user.api+"api/get_dealeraccount/" + login_id);
        result = await result.json();
        setDaccData(result);
        result = Object.values(result);
    }
    // ::::::::::::::::::::::::::::::::::::::::::::::: GET SEARCH DATA :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function editAccount(dealerid, total, paid) {
        setdealer_id(dealerid);
        settotal_amount(total);
        setprevpaid(paid);
        setremaining(total - paid);
        setremaining1(total - paid);
    }
    function caltotal() {
        let rem = remaining1 - currentpaid;
        if (rem < 0) {
            Swal.fire('Sorry!', 'Please Check Outstanding Amount', 'error')
            setremaining(total_amount - prevpaid);
            setcurrentpaid(0);
            setremaining1(total_amount - prevpaid);
    }
        else{
            setremaining(rem);
        }
    }
    
    async function insAmount() {
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('dealer_id', dealer_id);
        form_data.append('currentpaid', currentpaid);
        form_data.append('remaining', remaining);
        form_data.append('paiddate', paiddate);
        form_data.append('paytype', paytype);
            if (currentpaid == '' || paiddate=='' ) {
                    Swal.fire('Sorry!', 'Current paid Amount and Pay Date is Required', 'error')
            } 
            else {
                let result = await fetch(""+user.api+"api/insert_dealeraccountdata", { method: 'POST', body: form_data });
                result = await result.json();
                if (result === 1) {
                    Swal.fire('Success!', 'Dealer Payment Successfully Stored ', 'success');
                    window.location.reload();
                } else {
                    Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
                }
            }
        }

        async function viewLedger(id) {
            window.location.href = '/view-ledger/'+ id +'';
        }
    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br/>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| Dealer Accounts ||</button><br/><br/><br/>
                        <div className="card card-primary card-outline ex1">
                        {/* <div className="row p-1">
                            &nbsp;&nbsp;<input onKeyUp={getSearchData} type="search"  value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} className='form-control col-sm-4' placeholder='Enter Dealer Name to Search ......'/>&nbsp;
                        </div> */}
                        <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Dealer Name</th>
                                            <th>Dealer Mobile</th>
                                            <th>Total Amount</th>
                                            <th>Paid </th>
                                            <th>Remaining</th>
                                            <th width="135">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            DaccData.map((item, num = 1) =>
                                                <tr key={num+1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item[3][0]['dealer_name']}</td>
                                                    <td>{item[3][0]['mobile']}</td>
                                                    <td>{item[1]}</td>
                                                    <td>{item[2]}</td>
                                                    <td>{item[1] - item[2]}</td>
                                                    <td width="100">
                                                        <a  onClick={() => viewLedger(item[0])}   className="btn btn-sm btn-success" > <i className="fa fa-eye"></i></a>
                                                       &nbsp;<a onClick={() => editAccount(item[0], item[1], item[2])} data-toggle="modal" data-target="#acc-modal"     className="btn btn-sm btn-info" > <i className="fa fa-share"></i> Pay</a>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}
            <div className="modal fade" id="acc-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Pay Amount</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label> Total Amount</label> </div>
                                <div className="col-sm-4">
                                    <input type="text"  className="form-control" value={total_amount} onChange={(e) => settotal_amount(e.target.value)} readOnly/>
                                </div>
                                <div className="col-sm-2"><label>Prev Paid </label> </div>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" value={prevpaid} onChange={(e) => setprevpaid(e.target.value)} />
                                </div>
                            </div><br />
                           
                            <div className="row">
                                <div className="col-sm-2"><label> Pay Type</label> </div>
                                <div className="col-sm-4">
                                <select className='form-control' value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                                    <option>-- Select Payment Type --</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Bank">Bank</option>
                                    <option value="RTGS">RTGS</option>
                                    <option value="Online Pay">Online Pay</option>
                                </select>
                                </div>
                                <div className="col-sm-2"><label>Pay Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date"  className="form-control" value={paiddate} onChange={(e) => setpaiddate(e.target.value)} />
                                </div>
                                
                            </div><br />
                            <div className="row">
                            <   div className="col-sm-2"><label>Current Paid </label> </div>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" onKeyUp={caltotal} value={currentpaid} onChange={(e) => setcurrentpaid(e.target.value)} />
                                </div>
                                <div className="col-sm-2"><label>Remaining </label> </div>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" value={remaining} readOnly/>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={insAmount} className="btn btn-primary" data-dismiss="modal">Pay Amount</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
