import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import Moment from 'moment';
let user = JSON.parse(localStorage.getItem('user_info'));


export default function Dailyie() {
    const login_id = user['id'];
    const [dailyie, setdailyie] = useState([]);
    const [membData, setmembData] = useState([]);

    const [searchvalue, setsearchvalue] = useState("");
    const [name, setname] = useState("");
    const [mobile, setmobile] = useState("");
    const [address, setaddress] = useState("");

    const [ietype, setietype] = useState("");
    const [membid, setmembid] = useState("");
    const [paydate, setpaydate] = useState("");
    const [paytype, setpaytype] = useState("");
    const [amount, setamount] = useState("");
    const [descreption, setdescreption] = useState("");

    const [updailyieid, setupdailyieid] = useState("");
    const [upietype, setupietype] = useState("");
    const [umembid, setumembid] = useState("");
    const [uppaydate, setuppaydate] = useState("");
    const [uppaytype, setuppaytype] = useState("");
    const [upamount, setupamount] = useState("");
    const [updescreption, setupdescreption] = useState("");


    useEffect(async () => {
        getDailyie();
        if (searchvalue == '') {
            getDailyie();
        }
    }, [])

    // ::::::::::::::::::::::::::::::::::::::::::::::::::: INSERT ITEMS CODE START::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function insDailyIe() {
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('membid', membid);
        form_data.append('ietype', ietype);
        form_data.append('paydate', paydate);
        form_data.append('paytype', paytype);
        form_data.append('amount', amount);
        form_data.append('descreption', descreption);
        if (ietype == '' || paydate == '' || amount == '') {
            Swal.fire('Sorry!', 'Please Fill Some Details', 'error')
        }
        else {
            let result = await fetch(""+user.api+"api/insert_dailyie", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Daily Data Successfully Stored ', 'success');
                getDailyie(); setietype(""); setpaydate(""); setpaytype(""); setamount(""); setdescreption("");
            } else {
                Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
            }
        }
    }
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::: GET ITEMS LIST CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getDailyie() {
        let result = await fetch(""+user.api+"api/get_dailyie/" + login_id);
        result = await result.json();
        setdailyie(result[0]);
        setmembData(result[1]);
    }
    // :::::::::::::::::::::::::::::::::::::::::::::::::::::::: EDIT ITEM DETAILS START ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function editDailyie(id, ietype, paydate, paytype, amount, descreption,membid) {
        setupdailyieid(id);
        setupietype(ietype);
        setuppaydate(paydate);
        setuppaytype(paytype);
        setupamount(amount);
        setupdescreption(descreption);
        setumembid(membid);
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::::::: INSERT ITEMS CODE START::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function updateDailyie() {
        const form_data = new FormData();
        form_data.append('updailyieid', updailyieid);
        form_data.append('upietype', upietype);
        form_data.append('umembid', umembid);
        form_data.append('uppaydate', uppaydate);
        form_data.append('uppaytype', uppaytype);
        form_data.append('upamount', upamount);
        form_data.append('updescreption', updescreption);
        if (upietype == '' || updescreption == '') {
            Swal.fire('Sorry!', 'Please Fill Some Details', 'error')
        }
        else {
            let result = await fetch(""+user.api+"api/update_dailyie", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Daily IE  Successfully Updated ', 'success');
                getDailyie();
            } else {
                Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
            }
        }
    }

    // :::::::::::::::::::::::::::::::::::::::::::::::::::: DELETE ITEMS CODE START ::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function delDailyIE(id) {
        let result = await fetch(""+user.api+"api/delete_dailyie/" + id, {
            method: 'DELETE'
        }, []);
        result = await result.json();
        if (result === 1) {
            Swal.fire('Success!', 'Daily IE Successfully Deleted!', 'success')
            getDailyie();
        } else {
            Swal.fire('Sorry!', 'Something was Wrong!', 'error')
        }
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::: GET SEARCH DATA :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    async function getSearchData() {
        const form_data = new FormData();
        form_data.append('searchvalue', searchvalue);
        form_data.append('login_id', login_id);

        let result = await fetch(""+user.api+"api/dailyie_search", { method: 'POST', body: form_data });
        result = await result.json();
        setdailyie(result);
    }

    async function insMemebers() {
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('name', name);
        form_data.append('mobile', mobile);
        form_data.append('address', address);
        if (name == '') {
            Swal.fire('Sorry!', 'Please Enter Name', 'error')
        }
        else {
            let result = await fetch(""+user.api+"api/insert_expensmembsers", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', ' Data Successfully Stored ', 'success');
                getDailyie(); setname(""); setmobile(""); setaddress("");
            } else {
                Swal.fire('Sorry!', 'Something was Wrong! Please Try Again', 'error')
            }
        }
    }

    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary float-6" >|| Daily IE Management ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Daily IE</button>
                        &nbsp;<button className="btn btn-success btn-sm" data-toggle="modal" data-target="#insert-modal1">Add Expense Members</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className="row p-1">
                                &nbsp;&nbsp;<input onKeyUp={getSearchData} type="search" value={searchvalue} onChange={(e) => setsearchvalue(e.target.value)} className='form-control col-sm-4' placeholder='Enter Daily IE Details......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Type</th>
                                            <th>Expense Members</th>
                                            <th>Pay Date</th>
                                            <th>Pay Type</th>
                                            <th>Amount</th>
                                            <th>Descreption</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dailyie.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.type}</td>
                                                    <td>{item.name}</td>
                                                    <td>{Moment(item.pay_date).format('DD-MMM-YYYY')}</td>
                                                    <td>{item.pay_type}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.descreption}</td>
                                                    <td width="100">
                                                        <a onClick={() => editDailyie(item.id, item.type, item.pay_date, item.pay_type, item.amount, item.descreption, item.memb_id)} data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>
                                                        <a onClick={() => delDailyIE(item.id)} className="btn btn-sm btn-danger" > <i className="fa fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Daily IE </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>  Type</label> </div>
                                <div className="col-sm-4">
                                    <select className='form-control' value={ietype} onChange={(e) => setietype(e.target.value)} >
                                        <option></option>
                                        <option value="Income">Income</option>
                                        <option value="Expense">Expense</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>Pay Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date" value={paydate} onChange={(e) => setpaydate(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Pay Type</label> </div>
                                <div className="col-sm-4">
                                    <select className='form-control' value={paytype} onChange={(e) => setpaytype(e.target.value)} >
                                        <option></option>
                                        <option value="Cash">Cash</option>
                                        <option value="Bank">Bank</option>
                                        <option value="RTGS">RTGS</option>
                                        <option value="Online Pay">Online Pay</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>Amount</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={amount} onChange={(e) => setamount(e.target.value)} className="form-control" placeholder="Enter Amount ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Select descreption</label> </div>
                                <div className="col-sm-4">
                                    <textarea value={descreption} onChange={(e) => setdescreption(e.target.value)} className="form-control" placeholder="Enter Descreption of Amount ..." ></textarea>
                                </div>
                                {
                                    ietype == 'Expense' ?
                                        <>
                                            <div className="col-sm-2"><label>  Expense Members</label> </div>
                                            <div className="col-sm-4">
                                                <select className='form-control' value={membid} onChange={(e) => setmembid(e.target.value)} >
                                                    <option></option>
                                                    {
                                                        membData.map((item) =>
                                                            <option value={item.id} key={item.id}>{item.name}</option>
                                                        )}
                                                </select>
                                            </div>
                                        </>
                                        :
                                        null
                                }

                            </div><br />

                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={insDailyIe} className="btn btn-primary" data-dismiss="modal">Save Daily IE</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Daily IE</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>  Type</label> </div>
                                <div className="col-sm-4">
                                    <select className='form-control' value={upietype} onChange={(e) => setupietype(e.target.value)} >
                                        <option></option>
                                        <option value="Income">Income</option>
                                        <option value="Expense">Expense</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>Pay Date</label> </div>
                                <div className="col-sm-4">
                                    <input type="date" value={uppaydate} onChange={(e) => setuppaydate(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Pay Type</label> </div>
                                <div className="col-sm-4">
                                    <select className='form-control' value={uppaytype} onChange={(e) => setuppaytype(e.target.value)} >
                                        <option></option>
                                        <option value="Cash">Cash</option>
                                        <option value="Bank">Bank</option>
                                        <option value="RTGS">RTGS</option>
                                        <option value="Online Pay">Online Pay</option>
                                    </select>
                                </div>
                                <div className="col-sm-2"><label>Amount</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={upamount} onChange={(e) => setupamount(e.target.value)} className="form-control" placeholder="Enter Amount ..." />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label>  Select descreption</label> </div>
                                <div className="col-sm-4">
                                    <textarea value={updescreption} onChange={(e) => setupdescreption(e.target.value)} className="form-control" placeholder="Enter Descreption of Amount ..." ></textarea>
                                </div>
                                {
                                    upietype == 'Expense' ?
                                        <>
                                            <div className="col-sm-2"><label>  Expense Members</label> </div>
                                            <div className="col-sm-4">
                                                <select className='form-control' value={umembid} onChange={(e) => setumembid(e.target.value)} >
                                                    <option></option>
                                                    {
                                                        membData.map((item) =>
                                                            <option value={item.id} key={item.id}>{item.name}</option>
                                                        )}
                                                </select>
                                            </div>
                                        </>
                                        :
                                        null
                                }
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={updateDailyie} className="btn btn-primary" data-dismiss="modal">Update Daily IE</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="insert-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Expense Memebers </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-2"><label>Name</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-2"><label>Mobile</label> </div>
                                <div className="col-sm-4">
                                    <input type="number" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-sm-2"><label> Address</label> </div>
                                <div className="col-sm-4">
                                    <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={insMemebers} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
